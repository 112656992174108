<template>
  <div>
    <v-row>
      <v-col>
        <v-menu v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="formattedStartDate"
                          outlined dense hide-details
                          prepend-icon="event"
                          class="mt-5"
                          :placeholder="$t('add-rule-step-timer-definition.start-time.label')"
                          readonly
                          v-bind="attrs"
                          v-on="on"/>
          </template>
          <v-date-picker v-model="startDate"
                         no-title
                         scrollable
                         @input="setStartDate"/>
        </v-menu>
      </v-col>

      <v-col>
        <v-menu v-model="timeMenu"
                ref="timePickerMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y left
                min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="startTime"
                          outlined dense hide-details
                          prepend-icon="schedule"
                          class="mt-5"
                          :placeholder="$t('add-rule-step-timer-definition.trigger-time.label')"
                          readonly
                          v-bind="attrs"
                          v-on="on"/>
          </template>
          <v-time-picker v-model="startTime"
                         format="24hr"
                         scrollable
                         @click:minute="setStartTime(startTime)"/>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import formats from "@/scripts/formats";

export default {
  name: 'TimerStartTime',
  components: {},

  props: ['value'],

  data: function () {
    return {
      dateMenu: false,
      startDate: null,
      timeMenu: false,
      startTime: null,
    }
  },

  computed: {
    /**
     * returns localized startDate string
     * @returns {null|string}
     */
    formattedStartDate() {
      if (this.startDate) {
        return formats.formatDateString(this.startDate, this.$t('app.date-time-format.date-only'))
      }
      return null
    },
    formattedStartTime() {
      if (this.startTime) {
        return this.startTime + ':00'
      }
      return null
    },
    formattedStartDateForSaving() {
      if (this.startDate) {
        return formats.formatDateString(this.startDate, "YYYY-MM-DD")
      }
      return null
    }
  },

  methods: {
    setStartDate() {
      this.dateMenu = false
      this.updateModel()
    },

    setStartTime(time) {
      this.$refs.timePickerMenu.save(time)
      this.updateModel()
    },

    /**
     * updates v-model
     */
    updateModel() {
      if (this.startDate !== null && this.startTime !== null) {
        let data = this.formattedStartDateForSaving + ' ' + this.formattedStartTime
        this.$emit('input', data)
        this.$emit('change')
      }
    }
  },
};
</script>