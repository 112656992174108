import { render, staticRenderFns } from "./SensorDevice.vue?vue&type=template&id=65296d70&v-slot=%7B%20dense%3Adense%20%7D&"
import script from "./SensorDevice.vue?vue&type=script&lang=js&"
export * from "./SensorDevice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports