const conditionTypes = Object.freeze({
  timers: 'timers',
  properties: 'properties'
})

const actionTypes = Object.freeze({
  devices: 'devices',
  irControllers: 'ircontrollers',
  sceneRefs: 'sceneRefs',
  notifications: 'notifications',
  sgready: 'sgready',
  rmtThermostat: 'rmtthermostat'
})

module.exports = Object.freeze({
  conditionTypes, actionTypes
})
