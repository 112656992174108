<template>
  <content-card :title="$t('realTimeEnergyPrices.title')"
                icon="query_stats" v-if="show && prices !== null">
    <template v-slot:titleBar>
      <v-icon class="price_info material-icons-outlined" color="primary" @click="openSpotmarketDialog()">
        info
      </v-icon>
    </template>
    <template v-slot:content>
      <div class="wrapper px-5 py-3">
        <div class="actual">
          <div :class="[getPriceColorClass(current)]">{{ current }} {{ $t('realTimeEnergyPrices.unit') }}</div>
          <div>{{ $t('realTimeEnergyPrices.now') }}</div>
        </div>
        <div :class="['trend', 'pr-10', 'pt-2', getPriceColorClass(next)]">
          <span class="pr-4">{{ diff }} {{ $t('realTimeEnergyPrices.unit') }}</span>
          <v-icon :class="[getPriceColorClass(next)]">
            {{ trending }}
          </v-icon>
        </div>
        <div class="next">
          <div :class="[getPriceColorClass(next)]">{{ next }} {{ $t('realTimeEnergyPrices.unit') }}</div>
          <div>{{ getNextHour().format($t('realTimeEnergyPrices.time')) }}</div>
        </div>
      </div>
      <div class="px-5 pb-4">
        <v-btn depressed large :outlined="timescope !== 'today'" class="mr-4" color="primary" @click="setTimescope('today')">
          {{ $t('realTimeEnergyPrices.today') }}
        </v-btn>
        <v-btn depressed large :outlined="timescope !== 'tomorrow'" color="primary" @click="setTimescope('tomorrow')">
          {{ $t('realTimeEnergyPrices.tomorrow') }}
        </v-btn>
      </div>
      <line-chart v-if="hasData" ref="chart" :key="timescope" :options="chartOptions" :data="chartData"></line-chart>
      <div class="mt-12 text-center text-h6" v-else>{{ $t('realTimeEnergyPrices.no-data') }}</div>
    </template>
  </content-card>
</template>


<script>
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

import { Line as LineChart} from 'vue-chartjs'
import config from '@/config/config.app.json'
import ContentCard from "@/templates/components/ContentCard.vue";
import moment from 'moment';
import energyPriceCalculator from "@/scripts/energyPriceCalculator";

export default {
  name: "RealTimeEnergyPrices",
  components: {ContentCard, LineChart},
  data: () => ({
    timescope: 'today',
    priceMarker: 26,
    prices: null,
    loading: false
  }),
  methods: {
    setTimescope(scope) {
      this.timescope = scope
    },
    openSpotmarketDialog() {
      this.$root.bisadialog.toggle('infoText', true, {
        title: this.$t('realTimeEnergyPrices.sportmarket.title'),
        icon: 'query_stats',
        html: this.$t('realTimeEnergyPrices.sportmarket.text')
      })
    },
    getData() {
      this.loading = true
      this.$rhRequest.sendGet({
        endpoint: "spotmarkt",
      }, (response) => {
        this.prices = response.data?.data
        this.loading = false
        this.$emit('finishedEnergyPricesLoading', !!this.prices)
      }, (err) => {
        console.error(err);
        this.loading = false
        this.$emit('finishedEnergyPricesLoading', !!this.prices)
      })
    },
    getChartData() {
      let dataset = []
      let labels = []
      let colors = []

      for (let i = 0; i < 24; i++) {
        let m = moment().seconds(0).milliseconds(0).minutes(0).hour(i)
        labels.push(m.format(this.$t('realTimeEnergyPrices.time')))
        if (this.timescope === 'tomorrow') {
          m.add(1, 'day')
        }
        let current = moment().seconds(0).milliseconds(0).minutes(0)

        let price = (this.prices[m.unix() * 1000])
        if (typeof price !== 'undefined' && price !== null) {
          price = Math.round(price[1] * 100)
          let endPrice = energyPriceCalculator.calculateEndPrice(price)
          dataset.push(endPrice)
          let transparent = '70';
          if (m.unix() === current.unix()) {
            transparent = 'FF'
          }

          let color
          if (endPrice >= this.priceMarker) {
            color = this.getThemeColor('error')
          } else {
            color = this.getThemeColor('success')
          }
          colors.push(`${color}${transparent}`)
        }
      }

      return {
        labels: labels,
        datasets: [{
          data: dataset,
          backgroundColor: colors,
          borderColor: (context) => {
            const chart = context.chart;
            const {ctx, chartArea} = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return;
            }

            let yAxis = chart.scales['y'];
            // Get the y coordinates for the value 0 and for the value when the gradient color should change
            let yBottom = yAxis.getPixelForValue(0);
            let yTop = yAxis.getPixelForValue(this.priceMarker);
            // Create linear gradient
            let gradient = ctx.createLinearGradient(0, yBottom, 0, yTop);
            // Use minimal color gradient with the color stops between 0.98 and 1
            gradient.addColorStop(0.98, this.getThemeColor('success'));
            gradient.addColorStop(1, this.getThemeColor('warning'));

            return gradient;
          },
          borderWidth: 2,
          stepped: true
        }],
      }
    },
    getPriceColorClass(price) {
      if (price >= this.priceMarker) {
        return 'price_high'
      } else {
        return 'price_low'
      }
    },
    getCurrentHour() {
      return moment().seconds(0).milliseconds(0).minutes(0)
    },
    getNextHour() {
      return this.getCurrentHour().add(1, 'hours')
    },
    getThemeColor(color) {
      let theme = this.$vuetify.theme.themes.light
      if (this.$vuetify.theme.dark) {
        theme = this.$vuetify.theme.themes.dark
      }

      return theme[color]
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    current() {
      return energyPriceCalculator.calculateEndPrice(Math.round(this.prices[this.getCurrentHour().unix()*1000][1]*100))
    },
    next() {
      return energyPriceCalculator.calculateEndPrice(Math.round(this.prices[this.getNextHour().unix()*1000][1]*100))
    },
    diff() {
      return this.next - this.current;
    },
    show() {
      if (typeof config.hasSpotmarket === 'undefined' ) return false;
      return JSON.parse(config.hasSpotmarket);
    },
    trending() {
      if (this.current < this.next) {
        return 'trending_up'
      } else if (this.current > this.next) {
        return 'trending_down'
      } else {
        return 'trending_flat'
      }
    },
    hasData() {
      return this.getChartData().datasets[0].data.length > 0
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: ((tooltipItems) => {
                return `${tooltipItems.raw} ${this.$t('realTimeEnergyPrices.unit')}`
              })
            }
          }
        },
        scales: {
          y: {
            display: true,
            position: 'right',
            ticks: {
              callback: (val) => {
                return `${val} ${this.$t('realTimeEnergyPrices.unit')}`
              }
            }
          },
        },
      }
    },
    chartData() {
      return this.getChartData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/real-time-energy-prices.scss';
</style>
