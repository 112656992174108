<template>
  <fullscreen-overlay-frame :title="$t('energyline-data-support-dialog.title').toString()"
                            icon="support_agent"
                            color="primary"
                            centered
                            closable
                            @close="closeOverlay">

    <template v-slot:content>
      <div v-text="$t('energyline-data-support-dialog.info-text', {contractNumber: data?.contractNumber})"
           class="mb-8"/>

      <v-form v-model="valid">
        <v-select outlined
                  v-model="subject"
                  :label="$t('energyline-data-support-dialog.subject')"
                  :items="subjects"
                  :rules="categoryRules"/>

        <v-textarea outlined
                    v-model="userMessage"
                    counter="2000"
                    height="250"
                    no-resize
                    :label="$t('form-dialog.textarea.label')"
                    :rules="messageRules"/>

        <v-file-input outlined chips
                      if="fileInput"
                      v-model="file"
                      :label="$t('form-dialog.file-input.label')"
                      class="mt-4"
                      show-size
                      accept="image/png, image/jpeg, image/jpg, application/pdf"
                      :rules="fileRules"
                      prepend-icon="">
          <template v-slot:append>
            <label for="#fileInput"> <!-- opens file selection when clicked -->
              <v-icon color="primary">attach_file</v-icon>
            </label>
            <v-btn v-if="data?.showCaptureButton"
                   icon small
                   class="ml-2"
                   @click="openCamera">
              <v-icon color="primary">photo_camera</v-icon>
            </v-btn>
          </template>
        </v-file-input>
      </v-form>

      <div v-text="$t('form-dialog.response-info', {sender: sender})"
           class="mt-4"/>
    </template>

    <template v-slot:actions>
      <v-btn large depressed
             color="primary"
             class="font-weight-bold action-button"
             :disabled="!valid"
             :loading="sending"
             @click="sendForm">
        {{ $t('app.send') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import config from "@/config/config.app.json";

export default {
  /* global Camera */ // resolve Cordova Camera Plugin
  name: 'EnergylineDataSupportDialog',

  components: {
    FullscreenOverlayFrame
  },

  props: ['data'],

  data: () => ({
    maxFileSize: 3000000, // 3 MB
    file: null,
    sending: false,
    userMessage: '',
    subject: null,
    valid: true
  }),

  methods: {
    /**
     * This method opens the camera to take picture
     */
    openCamera: function () {
      let self = this
      navigator.camera.getPicture(function (url) {
            self.getFileContentAsBase64(url)
          }, function (err) {
            console.error(err)
          },
          {
            // returns path to image
            destinationType: Camera.DestinationType.FILE_URI,
            // opens camera
            sourceType: Camera.PictureSourceType.CAMERA,
            // returns file of type jpeg
            encodingType: Camera.EncodingType.JPEG,
            // quality of image (100 would be full resolution)
            quality: 50
          });
    },

    /**
     * This method gets base64 content of a photo file. Takes url to that file as parameter
     *
     * @param url -> string
     */
    getFileContentAsBase64: function (url) {
      let self = this
      window.resolveLocalFileSystemURL(url, function success(fileEntry) {
        fileEntry.file(
            function success(file) {

              if (file.size > self.maxFileSize) {
                this.$root.bisatoast.error({message: self.$t('form-dialog.rules.file-too-large')})
              }

              let reader = new FileReader();
              reader.onload = function () {
              }
              reader.onloadend = function () {
                // create Blob from file because cordova-camera-plugin image-files wouldn't be sent
                self.createBlob(this.result, file.name)
              }
              reader.onerror = function (error) {
                console.error(error);
              }

              reader.readAsDataURL(file);

            }, function (error) {
              console.error(error)
            })
      }, function (error) {
        console.error(error)
      })
    },

    /**
     * This method takes base64 file and creates and returns blob
     *
     * @param base64File -> string
     * @param fileName
     */
    createBlob: function (base64File, fileName) {
      let block = base64File.split(";");
      let contentType = block[0].split(":")[1];
      let realData = block[1].split(",")[1];
      let blob = this.b64toBlob(realData, contentType);
      blob.name = fileName
      this.file = blob
    },

    /**
     * This method creates blob
     *
     * @param b64Data -> string
     * @param contentType -> string
     * @param sliceSize
     */
    b64toBlob: function (b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, {type: contentType});
    },

    /**
     * This method submits the form which was filled by the user to the API
     */
    sendForm: function () {
      this.sending = true

      let mailBody = this.$t('contract-selection-card.contract-number') + ":\n" + this.data?.contractNumber
          + "\n\n" + this.$t('contract-selection-card.customer-id') + ":\n" + this.data?.customerId
          + "\n\n" + this.$t('energyline-data-support-dialog.client-key') + ":\n" + this.$t('energyline-data-support-dialog.mapped-client-key')
          + "\n\n" + this.$t('energyline-data-support-dialog.user-message') + ":\n" + this.userMessage

      let formData = new FormData();
      if (this.file) {
        formData.append('attachment', this.file, this.file.name)
      }
      formData.append('recipientAddress', config.superofficeRecipient)
      formData.append('replyTo', this.sender)
      formData.append('senderName', '')
      formData.append('subject', this.subject)
      formData.append('body', mailBody)
      formData.append('noSignature', true)

      let self = this
      this.$rhRequest.sendPost({
        endpoint: 'mail/send',
        serializer: 'multipart',
        header: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }, function () {
        // send copy to user
        self.sendCopy(mailBody);
      }, function (error) {
        self.sending = false
        console.error(error)
        self.$root.bisatoast.error({message: self.$t('app.generic-error'), showCloseBtn: true})
      })
    },

    /**
     * sends a copy to the user
     */
    sendCopy(mailBody) {
      let formData = new FormData();
      if (this.file) {
        formData.append('attachment', this.file, this.file.name)
      }
      formData.append('body', mailBody)
      formData.append('noSignature', true)
      formData.append('recipientAddress', this.sender)
      formData.append('subject', this.$t("form-section.subject.copy", {subject: this.subject}).toString())
      formData.append('noSignature', true)

      let self = this
      this.$rhRequest.sendPost({
        endpoint: 'mail/send',
        serializer: 'multipart',
        header: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }, function () {
        self.sending = false
        self.closeOverlay()
        self.$root.bisatoast.success({message: self.$t('form-dialog.send.success'), showCloseBtn: true})
      }, function (error) {
        self.sending = false
        console.error(error)
        self.$root.bisatoast.error({message: self.$t('app.generic-error'), showCloseBtn: true})
      })
    },

    /**
     * on dialog close
     */
    closeOverlay() {
      this.userMessage = ''
      this.$root.bisadialog.toggle('energylineDataSupport')
    }
  },

  computed: {
    subjects() {
      return config.superofficeSubjects.split(",")
    },

    categoryRules() {
      return [
        v => !!v || this.$t('app.rules.required')
      ]
    },

    fileRules() {
      return [
        value => !value || value.size < this.maxFileSize || this.$t('form-dialog.rules.file-too-large'),
      ]
    },

    messageRules() {
      return [
        v => !!v || this.$t('app.rules.required'),
        v => (v && v.length <= 2000) || this.$t('app.rules.too-long', {maxLength: 2000})
      ]
    },

    sender() {
      return localStorage.getItem('user')
    }
  },
};
</script>

