<template>
  <fullscreen-overlay-frame :title="title.toString()"
                            icon="settings_suggest"
                            color="primary"
                            centered
                            closable
                            @close="close">
    <template v-slot:content>
      <v-card>
        <v-card-text>
          <v-alert v-if="!siteCreated" type="warning">
            {{ $t('add-site-dialog.warning-message')  }}
          </v-alert>
          <v-form v-model="formValid" ref="addSiteForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="newSite.name"
                                :label="$t('add-site-dialog.name-label')"
                                required
                                outlined
                                :disabled="siteCreated"
                                :rules="requiredFieldRules"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="newSite.street"
                                :label="$t('add-site-dialog.street-label')"
                                required
                                outlined
                                :disabled="siteCreated"
                                :rules="requiredFieldRules"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field v-model="newSite.zip"
                                :label="$t('add-site-dialog.zip-code-label')"
                                required
                                outlined
                                :disabled="siteCreated"
                                counter="5"
                                :rules="zipCodeRules"/>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field v-model="newSite.city"
                                :label="$t('add-site-dialog.city-label')"
                                required
                                outlined
                                :disabled="siteCreated"
                                :rules="requiredFieldRules"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card v-if="siteCreated">
        <v-card-title color="primary">
          {{ $t('add-site-dialog.success') }}
        </v-card-title>
        <v-card-text v-if="hasSmarthome">
          <v-alert type="success">
            {{ $t('add-site-dialog.site-created-info-text') }}
          </v-alert>
          <v-btn
              large
              depressed
              block
              class="font-weight-bold"
              color="primary"
              @click="addGateway">
            <v-icon>add</v-icon>
            {{ $t('add-site-dialog.add-gateway') }}
          </v-btn>
        </v-card-text>
        <v-card-text v-else>
          <v-alert type="success">
            {{ $t('add-site-dialog.site-created-info-text.no-smarthome') }}
          </v-alert>
        </v-card-text>
      </v-card>
    </template>
    <template v-slot:actions v-if="!siteCreated">
      <v-btn large
             depressed
             block
             color="primary"
             :disabled="!formValid || loading"
             :loading="loading"
             class="font-weight-bold action-button"
             @click="save">
        <v-icon>add</v-icon>
        {{ $t('add-site-dialog.add-new-site') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>
<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame.vue";
import config from "@/config/config.app.json";

export default {
  name: "AddSiteDialog",
  components: {FullscreenOverlayFrame},
  data() {
    return {
      title: this.$t('add-site-dialog.title'),
      loading: false,
      siteCreated: false,
      formValid: true,
      newSite: {
        name: "",
        street: "",
        city: "",
        zip: "",
        // Dont get set
        addGwToExistingSite: false,
        mac: null,
      },
      zipCodeRules: [
        v => v.length > 0 || this.$t('add-site-dialog.required_field'),
        v => /^\d{5}$/.test(v) || this.$t('add-site-dialog.invalid_zip_code')
      ],
      requiredFieldRules: [
        v => !!v || this.$t('add-site-dialog.required_field')
      ]
    };
  },
  methods: {
    close() {
      this.$root.bisadialog.toggle('addSite')
    },
    addGateway() {
      this.close()
      this.$root.bisadialog.toggle('addGateway')
      this.$root.bisadialog.callDialogInit('addGateway')
    },
    save() {
      this.loading = true;
      this.$refs.addSiteForm.validate()
      if (!this.formValid || !this.$refs.addSiteForm.validate()) {
        this.loading = false;
        return
      }
      this.$rhRequest.sendPost({
        endpoint: "gateway-registration/skip/add",
        data: this.newSite
      }, (response) => {
        if (response.data.code === 0) {
          this.$root.bisatoast.success({
            message: this.$t('add-site-dialog.success'),
            showCloseBtn: true
          })
          this.siteCreated = true
          this.loading = false
        } else {
          this.$root.bisatoast.error({
            message: this.$t("app.generic-error"),
            showCloseBtn: true
          })
        }
        this.loading = false
      }, (e) => {
        console.error(e)
        this.$root.bisatoast.error({
          message: this.$t("app.generic-error"),
          showCloseBtn: true
        })
        this.loading = false
      })
    },
  },
  computed: {
    hasSmarthome() {
      return config.hasSmarthome !== 'false'
    },
  }
}
</script>