<!-- summary text for a user configured HAM action -->

<template>
  <span v-if="action?.switchOperation === 'TURN_ON'">
    {{ $t('action-summary-card.switch-operation.turn-on', {name: actionSubject?.device?.name}) }}
  </span>
  <span v-else-if="action?.switchOperation === 'TURN_OFF'">
    {{ $t('action-summary-card.switch-operation.turn-off', {name: actionSubject?.device?.name}) }}
  </span>
  <span v-else-if="action?.switchOperation === 'TOGGLE'">
    {{ $t('action-summary-card.switch-operation.toggle', {name: actionSubject?.device?.name}) }}
  </span>
  <span v-else-if="action?.setPoint !== null && action?.type === automations.actionTypes.devices">
    {{
      $t('action-summary-card.set-point', {
        name: actionSubject?.device?.name,
        value: action?.setPoint,
        unit: actionSubject?.actuator?.unit
      })
    }}
  </span>
  <span v-else-if="action?.setPoint !== null && action?.type === automations.actionTypes.rmtThermostat">
    {{
      $t('action-summary-card.set-point', {
        name: actionSubject?.device?.name,
        value: action?.setPoint,
        unit: actionSubject?.actuator?.unit
      })
    }}
  </span>
  <span v-else-if="action?.type === automations.actionTypes.sceneRefs"
        v-html="$t('action-summary-card.scene', {name: action?.name})"/>
  <!-- special case IR-Controller :/ -->
  <span v-else-if="action?.type === automations.actionTypes.irControllers">
    {{ $t('device-card-ir-controller.mode-label') }}: {{ action.thermostatMode }} ({{ processFanMode(action) }})
  </span>
  <span v-else-if="action?.type === automations.actionTypes.sgready">
    {{ $t('device-card-double-smart-module.mode-label') }}  {{ $t('double-smart-module.state.' + action.state) }}
  </span>
  <span v-else-if="action?.type === automations.actionTypes.notifications">
    <span v-if="action?.notificationType === 'PUSH'"
          v-text="$t('add-rule-step-notification-type-selection.push')"/>
    <span v-else
          v-text="$t('action-summary-card.notification', {type: getNotificationName(action.notificationType), recipientsCount: action.recipients.length})"/>
  </span>
  <span v-else-if="actionSubject?.device?.isSecureShelly2PM">
    <span v-if="action?.basicSetPoint === '1'">
      {{ $t('action-summary-card.switch-operation.turn-on', {name: actionSubject?.device?.name}) }}
    </span>
    <span v-else-if="action?.basicSetPoint === '0'">
      {{ $t('action-summary-card.switch-operation.turn-off', {name: actionSubject?.device?.name}) }}
    </span>
  </span>
  <!-- add cases for future action types here -->
</template>

<script>
import automations from "@/scripts/automations";

export default {
  name: 'ActionSummary',

  props: ['action', 'actionSubject'],

  computed: {
    automations() {
      return automations
    }
  },

  methods: {
    getNotificationName(type) {
      switch (type) {
        case 'EMAIL':
          return this.$t('add-rule-step-notification-type-selection.email')
        case 'SMS':
          return this.$t('add-rule-step-notification-type-selection.sms')
        case 'PUSH':
          return this.$t('add-rule-step-notification-type-selection.push')
        default:
          return null
      }
    },

    processFanMode(action) {
      return action.fanMode === 'OFF' ? this.$t('ir-controller-card.fan-mode-slider.off') : action.fanMode
    }
  }
}
</script>
