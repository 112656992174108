<template>
  <content-card class="mb-4"
                :title="$t('no-gateway-card.title').toString()"
                icon="broadcast_on_personal">
    <template v-slot:content>
      <div class="px-5 pt-5 font-size-03" v-html="$t('no-gateway-card.text')"/>
      <v-list>
        <v-list-item class="px-5">
          <v-list-item-icon class="mr-3">
            <v-icon color="primary"
                    class="material-icons-outlined">
              verified
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-size-03 text-wrap" v-html="$t('no-gateway-card.bulletpoint-1')"/>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="px-5">
          <v-list-item-icon class="mr-3">
            <v-icon color="primary"
                    class="material-icons-outlined">
              verified
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-size-03 text-wrap" v-html="$t('no-gateway-card.bulletpoint-2')"/>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="px-5 pb-5">
        <v-btn plain block
               v-if="showRoutingButton"
               color="primary"
               class="font-weight-bold"
               to="smarthome">
          {{ $t('no-gateway-card.add-gateway') }}
        </v-btn>
      </div>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard"

export default {
  name: "NoGatewayCard",

  components: {ContentCard},

  computed: {
    showRoutingButton() {
      return this.$route.name !== 'smarthome';
    }
  }
}
</script>
