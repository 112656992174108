<template>
  <content-card :title="sgReadyModule?.name" :icon="'heat_pump'">
    <template v-slot:titleBar>
      <v-icon class="sg-ready-icon status-symbol material-icons-outlined non-flip" color="success" v-if="isOnline">
        contactless
      </v-icon>
      <v-icon class="sg-ready-icon status-symbol material-icons-outlined non-flip" color="error" v-else>offline_bolt</v-icon>
    </template>
    <template v-slot:content>
      <double-switch-input :device="sgReadyModule" @updateDevice="$emit('updateDevice')"></double-switch-input>
      <s-g-ready-advanced-settings :device="sgReadyModule" :sg-ready-rules="sgReadyRules" :smart-meters="smartMeters"></s-g-ready-advanced-settings>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard.vue";
import DoubleSwitchInput from "@/templates/components/devices/actuators/DoubleSwitchInput.vue";
import SGReadyAdvancedSettings from "@/templates/components/devices/SGReadyAdvancedSettings.vue";

export default {
  name: 'SGReadyHomeEnergyCard',
  components: {SGReadyAdvancedSettings, DoubleSwitchInput, ContentCard},

  props: ['sgReadyModule', 'sgReadyRules', 'smartMeters'],
  computed: {
    isOnline() {
      return this.sgReadyModule?.online && this.sgReadyModule?.gatewayOnline
    },
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/sg-ready-home-energy.scss';
</style>