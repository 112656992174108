<template>
  <v-stepper-content step="12"
                     class="pa-1">

    <div v-html="$t('add-rule-step-set-point-definition.info-text')"/>

    <content-card :title="$t('add-rule-step-set-point-definition.set-point.title').toString()"
                  icon="tune"
                  class="my-5">
      <template v-slot:content>
        <v-slider v-model="setPoint"
                  :min="item?.actuator?.minValue"
                  :max="item?.actuator?.maxValue"
                  :label="$t('add-rule-step-set-point-definition.set-point.label', {unit: item?.actuator?.unit})"
                  class="align-center mx-5 mt-10"
                  thumb-label="always"
                  @change="updateSetPoint"/>
      </template>
    </content-card>
  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import automations from "@/scripts/automations";

export default {
  name: 'AddRuleStepRemotecThermostatSetPointDefinition',
  components: {
    ContentCard
  },

  props: {
    item: {
      type: Object,
      default: null
    },
    value: {
      type: Object,
      default: null
    }
  },

  data: function () {
    return {
      setPoint: null,
      switchOperation: null
    }
  },

  computed: {
    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      if (this.switchOperation !== null
          && this.item?.actuator?.dataType === 'boolean') {
        this.updateModel() // refresh v-model to current value
        return true
      } else if (this.setPoint !== null
          && this.setPoint >= this.item?.actuator?.minValue
          && this.setPoint <= this.item?.actuator?.maxValue
          && this.item?.actuator?.dataType !== 'boolean') {
        this.updateModel() // refresh v-model to current value
        return true
      }
      return false
    }
  },

  methods: {
    setSetPoint(value) {
      this.setPoint = value
      this.updateSetPoint()
    },

    updateSetPoint() {
      this.switchOperation = null
      this.updateModel()
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', {
        deviceId: this.item?.device?.id,
        setPoint: this.setPoint,
        type: automations.actionTypes.rmtThermostat // JSON:API Resource type
      })
    }
  }
};
</script>
