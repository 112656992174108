<template>
    <v-img contain width="50%"
           class="ma-auto"
           src="~@/assets/custom/images/header-logo.png"/>
</template>

<script>
export default {
  name: 'LogoNoText'
}
</script>
