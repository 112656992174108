<template>
  <v-stepper-content step="14"
                     class="pb-2 pa-1">
    <div v-html="$t('add-rule-step-notification-type-selection.info-text')"/>

    <content-card :title="$t('add-rule-step-notification-definition.notification-type.title').toString()"
                  icon="notifications"
                  class="my-5">
      <template v-slot:content>
        <v-list class="pa-0">
          <v-radio-group v-model="notificationType"
                         class="pa-0 ma-0"
                         @change="updateModel"
                         hide-details>
            <v-list-item v-if="showMail"
                         class="list-item straight">
              <v-list-item-icon class="mr-2">
                <v-radio value="EMAIL"
                         class="pt-2 mr-2"/>
                <v-icon color="primary">mail</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold font-size-03">
                  {{ $t('add-rule-step-notification-type-selection.email') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="list-item straight">
              <v-list-item-icon class="mr-2">
                <v-radio value="SMS"
                         class="pt-2 mr-2"/>
                <v-icon color="primary">sms</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold font-size-03">
                  {{ $t('add-rule-step-notification-type-selection.sms') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="list-item straight">
              <v-list-item-icon class="mr-2">
                <v-radio value="PUSH"
                         class="pt-2 mr-2"/>
                <v-icon color="primary">ad_units</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold font-size-03">
                  {{ $t('add-rule-step-notification-type-selection.push') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-radio-group>
        </v-list>
      </template>
    </content-card>
  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";

export default {
  name: 'AddRuleStepNotificationTypeSelection',
  components: {
    ContentCard
  },

  props: {
    /**
     * v-model
     */
    value: {
      type: String,
      default: null
    },
    actions: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data: function () {
    return {
      showMail: true,
      notificationType: null
    }
  },

  computed: {
    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      return this.notificationType !== null
    }
  },

  methods: {
    /**
     * initializes step data
     *
     * @param type should be null, when adding a new action
     */
    init(type = null) {
      this.notificationType = type

      this.updateModel()
      this.checkMailVisibility()
    },

    /**
     * checks if notification per mail should be visible
     */
    checkMailVisibility() {
      this.showMail = true
      //mail is not selected (existing action)
      if (this.notificationType !== 'EMAIL') {
        this.actions.forEach((action) => {
          //mail is not already selected in this scene
          if (action?.notificationType === 'EMAIL') {
            this.showMail = false
          }
        })
      }
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', this.notificationType)
    }
  }
};
</script>
