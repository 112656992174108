import VueI18n from 'vue-i18n'
import config from './config/config.app.json'
import translations from '@/config/translations.json'
import Vue from 'vue'

Vue.use(VueI18n)

function getLanguage() {
    let language
    if (config.availableLanguages.length === 2) {
        language = config.availableLanguages
    } else if (!localStorage.getItem('langSetting')) {
        if (config.availableLanguages.includes('ar') && navigator.language.slice(0, 2) === 'ar') {
            language = 'ar'
        } else if (config.availableLanguages.includes('de') && navigator.language.slice(0, 2) === 'de') {
            language = 'de'
        } else if (config.availableLanguages.includes('it') && navigator.language.slice(0, 2) === 'it') {
            language = 'it'
        } else if (config.availableLanguages.includes('pt') && navigator.language.slice(0, 2) === 'pt') {
            language = 'pt'
        } else if (config.availableLanguages.includes('nl') && navigator.language.slice(0, 2) === 'nl') {
            language = 'nl'
        } else if (config.availableLanguages.includes('fr') && navigator.language.slice(0, 2) === 'fr') {
            language = 'fr'
        } else {
            language = 'en'
        }
        localStorage.setItem('langSetting', language)
    } else {
        language = localStorage.getItem('langSetting')
    }
    let options = {
        endpoint: 'language/set',
        data: {
            language: language,
            persist: true
        }
    }

    // $nextTick required because without $rhRequest will be undefined
    Vue.prototype.$nextTick(() => {
        Vue.prototype.$rhRequest.sendPost(options, {}, (e) => {
            console.error(e)
        })
    })
    return language
}

function loadLocaleMessages(translations) {
    if (!translations.data.length) {
        console.log('Cannot find any translations')
        return
    }

    let locales = []
    for (let key in translations.data[0].translations) {
        locales.push(key)
    }

    const messages = {}
    for (let localeKey of locales) {
        messages[localeKey] = {}
        for (let translation of translations.data) {
            let key = translation.key
            let value = translation.translations[localeKey]
            messages[localeKey][key] = value
        }
    }
    return messages
}

const messages = {
    en: loadLocaleMessages(translations).en,
    de: loadLocaleMessages(translations).de,
    it: loadLocaleMessages(translations).it,
    ar: loadLocaleMessages(translations).ar,
    nl: loadLocaleMessages(translations).nl,
    pt: loadLocaleMessages(translations).pt,
    fr: loadLocaleMessages(translations).fr
}

const nonFlipIcon = [
    'update',
    'history',
    'offline_bolt',
    'bolt',
    'schedule',
    'question_mark',
    'battery_unknown',
    'picture_as_pdf',
    'live_help',
    'mark_email_read',
    'checkbox',
    'check_circle_outline'
]

const i18n = new VueI18n({
    locale: getLanguage(),
    fallbackLocale: 'en',
    messages
})

export default i18n

export function isNonFlipIcon(iconName) {
    return nonFlipIcon.includes(iconName);
}
