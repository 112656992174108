<template>
  <div v-if="isSiteAdmin && (device?.hasOwnProperty('battery-level') || device?.alarmProperty)">
    <v-expansion-panels>
      <v-expansion-panel class="tileBackground">
        <v-expansion-panel-header class="px-5">
          <div>
            <v-icon class="material-icons-outlined non-flip"
                    color="primary">
              notification_important
            </v-icon>
            <span class="font-weight-bold font-size-04 mr-4"
                  :class="'primary' + '--text'">
                    {{ $t('device-alerts.title') }}
                  </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-alert v-if="showInfo"
                     type="info"
                     text
                     class="font-size-03 mb-5"
                     border="left">
              {{ $t('device-alerts.info') }}
            </v-alert>
            <v-row>
              <v-chip class="my-1 mr-2" v-if="isSiteAdmin && device?.hasOwnProperty('battery-level')" :disabled="!isGatewayOnline"
                      :color="device.alert_battery_active ? 'primary' : ''" @click="updateBatteryAlert">
                <v-icon left>battery_alert</v-icon>
                {{ $t('device-alerts.battery-alert') }}
                <v-tooltip class="higher-z-index" v-model="showBatteryTooltip" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        class="material-icons-outlined non-flip"
                        color="gray"
                        @click.stop="showBatteryTooltip = !showBatteryTooltip"
                        v-bind="attrs"
                        v-on="on"
                        right>
                      info
                    </v-icon>
                  </template>
                  <span>{{ $t('device-alerts.battery-alert.tooltip') }}</span>
                </v-tooltip>
              </v-chip>
              <v-chip class="my-1 mr-2" v-if="isSiteAdmin && device?.alarmProperty" :disabled="!isGatewayOnline"
                      :color="device.alert_alarm_active ? 'primary' : ''" @click="updateAlarmAlert">
                <span class="material-symbols-outlined v-icon">detector_alarm</span>
                {{ $t('device-alerts.alarm-alert') }}
                <v-tooltip class="higher-z-index" v-model="showAlarmTooltip" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        class="material-icons-outlined non-flip"
                        color="gray"
                        @click.stop="showAlarmTooltip = !showAlarmTooltip"
                        v-bind="attrs"
                        v-on="on"
                        right>
                      info
                    </v-icon>
                  </template>
                  <span>{{ $t('device-alerts.alarm-alert.tooltip') }}</span>
                </v-tooltip>
              </v-chip>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "DeviceAlerts",
  props: {
    device: Object,
    isGatewayOnline: Boolean,
    isSiteAdmin: Boolean,
    showInfo: Boolean,
  },
  data: function () {
    return {
      showBatteryTooltip: false,
      showAlarmTooltip: false,
    }
  },
  methods: {
    updateBatteryAlert() {
      let self = this
      let options = {
        endpoint: 'device-alert/set',
        data: {
          "deviceId": this.device.encryptedId,
          "type": "battery",
          "active": !this.device.alert_battery_active
        }
      }
      this.$rhRequest.sendPost(
          options,
          () => {
            self.updateDeviceAlert('alert_battery_active')
          }, (err) => {
            console.error(err)
          }
      )
      this.$emit('updatedAlert')
    },
    updateAlarmAlert() {
      let self = this
      let options = {
        endpoint: 'device-alert/set',
        data: {
          "deviceId": this.device.encryptedId,
          "type": "alarm",
          "active": !this.device.alert_alarm_active,
          "propertyName": this.device.alarmProperty
        }
      }
      this.$rhRequest.sendPost(
          options,
          () => {
            self.updateDeviceAlert('alert_alarm_active')
          }, (err) => {
            console.error(err)
          }, () => {

          }
      )
    },
    updateDeviceAlert(alertName) {
      this.$emit('updatedAlert', alertName)
    }
  },
  computed: {},
}
</script>

<style lang="scss">
@import '~@/styles/device.scss';
</style>