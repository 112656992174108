<template>
  <dialog-frame :title="$t('account-linking-dialog.title').toString()"
                icon="sync_alt"
                :color="dialogColor"
                closable
                @close="abort">
    <template v-slot:content>
      {{ dialogText }}

      <v-form v-model="valid" ref="form">
        <v-text-field class="account-linking-input mt-5 mx-auto"
                      dense outlined
                      :rules="reqRules.concat(vpKundenNrRules)"
                      v-model="vpKundenNr"
                      :label="$t('account-linking-dialog.vpKundenNr').toString()"
                      single-line/>
        <v-text-field class="account-linking-input mx-auto"
                      dense outlined
                      :rules="reqRules.concat(anmeldeschluesselRules)"
                      v-model="anmeldeschluessel"
                      :label="$t('account-linking-dialog.anmeldeschluessel').toString()"
                      single-line/>
      </v-form>

    </template>

    <template v-slot:actions>
      <v-btn plain block
             :disabled="!valid"
             color="primary"
             class="font-weight-bold d-block"
             @click="postEnergylineAccountLinkings"
             :loading="loading">
        {{ $t('app.send') }}
      </v-btn>
    </template>
  </dialog-frame>
</template>

<script>

import DialogFrame from "@/templates/dialogs/DialogFrame";

export default {
  name: 'AccountLinkingDialog',

  components: {
    DialogFrame
  },

  data: () => ({
    valid: false,
    code: null,
    loading: false,
    vpKundenNr: null,
    anmeldeschluessel: null,
    anmeldeschluesselLength: 19, // 16 + 3 (for hyphens)
    showErrorDialogColor: false
  }),

  methods: {
    abort() {
      this.$root.bisadialog.toggle('energylineAccountLinking')
      this.$root.$emit('account-linking-dialog-close')
      this.$refs.form.reset()
      this.showErrorDialogColor = false
    },
    postEnergylineAccountLinkings () {
      this.loading = true
      this.$rhRequest.sendPost({
        endpoint: "consumption-data-service/energyline-account-linkings",
        data: {
          "vpKundenNr": this.vpKundenNr,
          "anmeldeschluessel": this.anmeldeschluessel
        }
      }, () => {
        this.$root.bisatoast.success({
          message: this.$t("account-linking-dialog.account-linking.success")
        })
        this.$root.bisadialog.toggle('energylineAccountLinking')
        this.$root.$emit('account-linking-dialog-close')
        this.$refs.form.reset()
        this.loading = false
      }, (error) => {
        // return code 403: inserted data is not correct,
        if(error?.response?.status === 403) {
          this.loading = false
          this.showErrorDialogColor = true
        }
        else {
          this.$root.bisatoast.error({
            message: this.$t("account-linking-dialog.account-linking.error")
          })
          this.$root.bisadialog.toggle('energylineAccountLinking')
          this.$root.$emit('account-linking-dialog-close')
          this.$refs.form.reset()
        }
        this.loading = false
      })
    }
  },

  mounted() {
    this.showErrorDialogColor = false
  },

  watch: {
    anmeldeschluessel() {
      let realKey = this.anmeldeschluessel.replace(/-/gi, '')
      let dashedKey = realKey.match(/.{1,4}/g)
      this.anmeldeschluessel = dashedKey.join('-')
    }
  },

  computed: {
    reqRules() {
      return [
        v => !!v || this.$t('app.rules.required')
      ]
    },
    anmeldeschluesselRules() {
      return [
        v => (v && v.length === this.anmeldeschluesselLength) || this.$t('account-linking-dialog.rules.anmeldeschluessel-not-fitting')
      ]
    },
    vpKundenNrRules() {
      return [
        v => (v && v.length < 50) || this.$t('account-linking-dialog.rules.vpKundenNr-too-long')
      ]
    },
    dialogColor () {
      return this.showErrorDialogColor ? 'error' : 'primary'
    },
    dialogText () {
      return this.showErrorDialogColor ? this.$t('account-linking-dialog.text-error') : this.$t('account-linking-dialog.text')
    }
  }
};
</script>

