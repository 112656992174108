<template>
  <v-stepper-content step="12"
                     class="pb-2 pa-1">
    <div v-html="$t('add-rule-step-when-condition-selection.info-text')"/>

    <content-card :title="$t('add-rule-step-set-point-definition.set-point.title').toString()"
                  icon="tune"
                  class="my-5">
      <template v-slot:content>

        <v-row class="px-5 pt-5">
          <v-col cols="6">
            <v-select outlined
                      v-model="controllerModeSelection"
                      :items="controllerModes"
                      item-text="text"
                      item-value="value"
                      :label="$t('device-card-ir-controller.mode-label')"
                      hide-details
                      @change="updateModel">
            </v-select>
          </v-col>

          <v-col cols="6">
            <v-select outlined
                      :disabled="controllerModeSelection === 'OFF'"
                      v-model="fanModeSelection"
                      :items="fanModes"
                      item-text="text"
                      item-value="value"
                      :label="$t('device-card-ir-controller.setpoint-fans')"
                      hide-details
                      @change="updateModel">
            </v-select>
          </v-col>
        </v-row>


        <v-slider v-model="temperatureSetPoint"
                  :disabled="controllerModeSelection !== 'HEAT' && controllerModeSelection !== 'COOL'"
                  min="16"
                  max="30"
                  :label="$t('add-rule-step-set-point-definition.set-point.label', {unit: '°C'})"
                  class="align-center mx-5 mt-15"
                  thumb-label="always"
                  @change="updateModel"/>
      </template>
    </content-card>
  </v-stepper-content>
</template>

<script>

import ContentCard from "@/templates/components/ContentCard.vue";
import irControllerOperations from "@/plugins/irControllerOperations/irControllerOperations";
import {actionTypes} from "@/scripts/automations";

export default {
  name: 'AddRuleStepIRControllerActionDefinition',
  components: {ContentCard},

  props: ['item', 'value'],

  data: function () {
    return {
      controllerModeSelection: null,
      fanModes: this.controllerFanModes(),
      fanModeSelection: null,
      temperatureSetPoint: null
    }
  },

  computed: {
    irControllerOperations() {
      return irControllerOperations
    },
    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      if (this.controllerModeSelection === 'OFF') return true;

      return this.controllerModeSelection != null
          && this.fanModeSelection != null
    },

    controllerModes() {
      let modes = []
      Object.keys(this.$irControllerOperations.ControllerModes).forEach(item => {
        switch (item) {
          case "AUTO":
            modes.push({text: item, value: item})
            break
          case "OFF":
            modes.push({text: item, value: item})
            break
          case "COOL":
            modes.push({text: this.$t('ir-controller-card.mode-label.cool'), value: item})
            break
          case "HEAT":
            modes.push({text: this.$t('ir-controller-card.mode-label.heat'), value: item})
            break
          case "DRY_AIR":
            modes.push({text: this.$t('ir-controller-card.mode-label.dry-air'), value: item})
            break
          case "FAN_ONLY":
            modes.push({text: this.$t('ir-controller-card.mode-label.fan-only'), value: item})
            break
          case "RESUME":
            modes.push({text: item, value: item})
            break
        }
      })
      return modes
    }
  },

  methods: {
    init() {
      this.updateModel()
    },

    /**
     * updates v-model
     */
    updateModel() {
      let data = {
        type: actionTypes.irControllers,
        deviceId: this.item.device.id,
        temperature: this.temperatureSetPoint,
        thermostatMode: this.controllerModeSelection,
        fanMode: this.fanModeSelection
      }

      this.$emit('input', data)
    },

    controllerFanModes() {
      let modes = []
      Object.keys(this.$irControllerOperations.FanModes).forEach(item => {
        switch (item) {
          case "LOW":
            modes.push({text: this.$t('ir-controller-card.fan-mode-slider.low'), value: item})
            break
          case "OFF":
            modes.push({text: this.$t('ir-controller-card.fan-mode-slider.off'), value: item})
            break
          case "HIGH":
            modes.push({text: this.$t('ir-controller-card.fan-mode-slider.high'), value: item})
            break
          case "AUTO":
            modes.push({text: this.$t('ir-controller-card.fan-mode-slider.auto'), value: item})
            break
        }
      })
      return modes
    }
  }
}
</script>
