<template>
  <fullscreen-overlay-frame :title="title"
                            id="heating-mode-overlay"
                            :icon="titleIcon"
                            :color="primaryColor"
                            centered
                            closable
                            @close="close">

    <template v-slot:content v-if="!editHeatingPointVisible">

      <!-- Active status and week days -->
      <v-card class="my-5 pa-5">
        <div class="d-flex justify-space-between mb-5">
          <span class="mt-1" :class="heatingModeActive ? '' : 'font-weight-bold primary--text'">{{ $t('heating-mode-dialog.inactive') }}</span>
          <v-switch class="mt-0"
                    v-model="heatingModeActive"
                    inset hide-details/>
          <span class="mt-1" :class="heatingModeActive ? 'primary--text font-weight-bold' : ''">{{ $t('heating-mode-dialog.active') }}</span>
        </div>
        <div class="d-flex justify-space-between">
          <v-chip class="mx-1 white--text font-size-02 font-weight-bold"
                  v-for="(day, key) in weekdays"
                  v-bind:key="key"
                  :color="day.active ? heatingModeColor : 'blue-grey lighten-4'"
                  @click="activateDay(key)">
            {{ $t(day.translation) }}
          </v-chip>
        </div>
      </v-card>

      <!-- Heating points -->
      <content-card :title="$t('heating-mode-dialog.card-headline.heating-intervals')"
                    icon="update"
                    class="my-5">
        <template v-slot:content>
          <v-simple-table>
            <template v-slot:default>
              <tbody class="tileBackground">
              <tr v-for="(item, index) in heatingPoints" :key="index" @click="selectHeatingPoint(item, index)">
                <td class="primary--text font-weight-bold">{{ item.name }}</td>
                <td> {{ formatTime(item.hour, item.minute) }} {{ $t('app.unit.o-clock') }}</td> <!-- Unterscheidung AM PM -->
                <td class="pr-0">
                  <v-chip
                      class="ma-2 white--text"
                      :color="returnTemperatureColor(item.value)"
                  >
                    {{ item.value }}<sup>{{ $t('app.unit.celsius') }}</sup>
                  </v-chip>
                </td>
                <td class="pl-0">
                  <v-icon>chevron_right</v-icon>
                </td>
              </tr>
              <tr>
                <td colspan="4" class="primary--text font-weight-bold" @click="selectHeatingPoint">
                  <v-icon color="primary">add</v-icon>
                  {{ $t('heating-mode-dialog.heating-interval-card.add-time') }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </template>
      </content-card>

      <v-skeleton-loader v-if="loading"
                         class="mb-5 heating-devices-skeleton"
                         type="table"/>

      <content-card :title="$t('heating-mode-dialog.card-headline.active-devices')"
                    icon="contactless"
                    class="my-5"
                    v-else>
        <template v-slot:content>
          <v-simple-table>
            <template v-slot:default>
              <tbody class="tileBackground">
              <tr v-for="(item, index) in heatingDevices" :key="index">
                <v-list-item dense class="ma-0">
                  <v-list-item-icon class="my-auto">
                    <v-icon class="material-icons-outlined" color="primary">device_thermostat</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ item.name }}
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox @change="toggleDeviceActivity(index)" v-model="item.active"/>
                  </v-list-item-action>
                </v-list-item>
                <v-divider/>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </template>
      </content-card>

      <!-- General information -->
      <content-card :title="$t('heating-mode-dialog.card-headline.general-information')"
                    icon="info"
                    class="my-5">
        <template v-slot:content>
          <v-list subheader>
            <!-- Name -->
            <v-list-item dense two-line class="ma-0">
              <v-list-item-icon class="my-auto">
                <v-icon class="material-icons-outlined" color="primary">label</v-icon>
              </v-list-item-icon>
              <v-list-item-content v-if="!editName">
                <v-list-item-title class="primary--text font-weight-bold">{{ modeName }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('heating-mode-dialog.general-information-card.heating-mode-name') }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-text-field outlined dense
                              hide-details
                              ref="device-name-input"
                              v-model="modeName"/>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="editName = true" v-if="!editName">
                  <v-icon class="material-icons-outlined" color="primary">edit</v-icon>
                </v-btn>
                <v-btn icon @click="editName = false" v-else>
                  <v-icon class="material-icons-outlined" color="primary">check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider/>
            <!-- Color -->
            <v-list-item dense>
              <v-list-item-icon class="my-auto">
                <v-chip class="heating-scene-color-chip mt-1" :color="heatingModeColor"></v-chip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="primary--text font-weight-bold">{{ $t('heating-mode-dialog.general-information-card.color-signature') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="editColor = true" v-if="!editColor">
                  <v-icon class="material-icons-outlined" color="primary">edit</v-icon>
                </v-btn>
                <v-btn icon @click="editColor = false" v-else>
                  <v-icon class="material-icons-outlined" color="primary">check</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item dense v-if="editColor">
              <swatch-color-picker class="mx-auto" :color="heatingModeColor" @changeColor="changeHeatingModeColor"/>
            </v-list-item>

          </v-list>
        </template>
      </content-card>

      <v-btn large
             :disabled="loading || deviceSelectionIsEmpty"
             color="primary"
             class="font-weight-bold block-sm mx-auto d-block my-8"
             @click="startSaveProcess">
        <v-icon class="material-icons-outlined" left>add</v-icon>
        {{ $t('heating-mode-dialog.save') }}
      </v-btn>

      <v-btn depressed large outlined
             color="primary"
             class="font-weight-bold block-sm mx-auto d-block my-8"
             @click="startDeleteProcess">
        <v-icon class="material-icons-outlined" left>delete</v-icon>
        {{ $t('heating-mode-dialog.delete') }}
      </v-btn>

      <div id="heating-mode-save-screen" v-if="saveCircle">
        <v-progress-circular
            :rotate="360"
            :size="100"
            :width="15"
            :value="loadingValue"
            color="primary"
        >
          {{ loadingValue }}
        </v-progress-circular>
      </div>

    </template>
    <template v-slot:content v-else-if="editHeatingPointVisible">
      <add-heating-point-dialog @addHeatingPoint='addHeatingPoint'
                                @deleteHeatingPoint="deleteHeatingPoint"
                                :prop-temperature="selectedHeatingPoint.temperature"
                                :prop-heating-point-name="selectedHeatingPoint.heatingPointName"
                                :prop-selected-time="selectedHeatingPoint.selectedTime"
                                :prop-arr-index="selectedHeatingPoint.arrIndex"/>
    </template>

  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import ContentCard from "@/templates/components/ContentCard";
import SwatchColorPicker from "@/templates/components/SwatchColorPicker";
import moment from "moment/moment";
import AddHeatingPointDialog from "@/templates/dialogs/addAutomation/AddHeatingPointDialog";

export default {
  name: 'AddHeatingModeDialog',
  components: {
    FullscreenOverlayFrame,
    ContentCard,
    SwatchColorPicker,
    AddHeatingPointDialog
  },
  props: ['data'],
  data: function () {
    return {
      title: this.$t('add-heating-mode-dialog.title'),
      titleIcon: 'thermostat_auto',
      saveCircle: false,
      loadingValue: 0,
      loading: true,
      deviceSelectionIsEmpty: true,
      isDelete: false,
      newHeatingPlan: true,
      editHeatingPointVisible: false,
      modeName: '',
      editName: false,
      editColor: false,
      heatingModeActive: true,
      preSelectedDevices: [],
      createDevicePropertyMapQueue: [],
      storedTimeScheduleIds: [],
      timeScheduleQueue: [],
      heatingDevices: [],
      timeScheduleIds: [],
      selectedHeatingPoint: {},
      heatingPoints: [],
      weekdays: [],
      primaryColor: 'primary',
      heatingModeColor: '',
      heatingPlanId: '',
    }
  },
  methods: {
    init() {
      this.newHeatingPlan = !this.data?.heatingMode?.id
      this.heatingPlanId = this.data?.heatingMode?.id
      this.heatingModeActive = this.data?.heatingMode?.active ?? true
      this.modeName = this.data?.heatingMode?.name ?? this.$t('heating-mode-dialog.default-name')
      this.heatingModeColor = this.data?.heatingMode?.color ?? '#FFB000'
      this.createPreSelectedDevices()
      this.initWeekdays()
      this.initHeatingPoints()
      this.getDevices()
    },
    createPreSelectedDevices() {
      this.data?.heatingMode?.includedTimeSchedules?.[0]?.devices.forEach((device) => {
        this.preSelectedDevices.push(device)
      })
    },
    initHeatingPoints() {
      if (this.data?.heatingMode?.includedTimeSchedules) {
        this.data?.heatingMode?.includedTimeSchedules.forEach((timeSchedule) => {
          this.storedTimeScheduleIds.push(timeSchedule.id)
        })
      }

      this.heatingPoints = this.data?.heatingMode?.includedTimeSchedules ?? [{
        name: this.$t('heating-mode-dialog.default-heating-points.morning'),
        hour: 7,
        minute: 0,
        seconds: 0,
        value: 22.0
      }, {
        name: this.$t('heating-mode-dialog.default-heating-points.midday'),
        hour: 12,
        minute: 0,
        seconds: 0,
        value: 12.0
      }, {
        name: this.$t('heating-mode-dialog.default-heating-points.afternoon'),
        hour: 18,
        minute: 0,
        seconds: 0,
        value: 18.0
      }, {
        name: this.$t('heating-mode-dialog.default-heating-points.night'),
        hour: 22,
        minute: 0,
        seconds: 0,
        value: 16.0
      }]
    },
    initWeekdays() {
      this.weekdays = [{
        translation: 'heating-mode-dialog.weekdays.monday-shortcut',
        active: this.data?.heatingMode?.weekdays?.[0] ?? true
      }, {
        translation: 'heating-mode-dialog.weekdays.tuesday-shortcut',
        active: this.data?.heatingMode?.weekdays?.[1] ?? true
      }, {
        translation: 'heating-mode-dialog.weekdays.wednesday-shortcut',
        active: this.data?.heatingMode?.weekdays?.[2] ?? true
      }, {
        translation: 'heating-mode-dialog.weekdays.thursday-shortcut',
        active: this.data?.heatingMode?.weekdays?.[3] ?? true
      }, {
        translation: 'heating-mode-dialog.weekdays.friday-shortcut',
        active: this.data?.heatingMode?.weekdays?.[4] ?? true
      }, {
        translation: 'heating-mode-dialog.weekdays.saturday-shortcut',
        active: this.data?.heatingMode?.weekdays?.[5] ?? false
      }, {
        translation: 'heating-mode-dialog.weekdays.sunday-shortcut',
        active: this.data?.heatingMode?.weekdays?.[6] ?? false
      }]
    },
    /**
     * Opens the selected heating point with it's specific information by array index
     * @param heatingPoint
     * @param index
     */
    selectHeatingPoint(heatingPoint, index) {
      if (heatingPoint) {
        this.selectedHeatingPoint.temperature = heatingPoint?.value
        this.selectedHeatingPoint.heatingPointName = heatingPoint?.name
        this.selectedHeatingPoint.selectedTime = heatingPoint?.hour ? moment(heatingPoint.hour + ':' + heatingPoint.minute, 'HH:mm').format('HH:mm') : undefined
        this.selectedHeatingPoint.arrIndex = index
      }
      this.showEditHeatingPoint()
    },
    /**
     * In case that there is no index this function adds a new heating point.
     * If there is an array index it updates the existing heating point by array index.
     *
     * @param heatingPoint
     * @param index
     */
    addHeatingPoint(heatingPoint, index) {
      if (index === false) {
        this.heatingPoints.push(heatingPoint)
        this.sortHeatingPoints()
      } else {
        this.heatingPoints[index] = heatingPoint
      }
      this.hideEditHeatingPoint()
    },
    /**
     * This function sorts the heating points by time
     */
    sortHeatingPoints() {
      this.heatingPoints.sort((a, b) => {
        let keyA = moment(a.hour + ':' + a.minute, 'HH:mm')
        let keyB = moment(b.hour + ':' + b.minute, 'HH:mm')

        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      })
    },
    /**
     * Shows edit resp. create dialog for a heating point
     */
    showEditHeatingPoint() {
      this.title = this.$t('add-heating-mode-dialog.add-heating-point')
      this.titleIcon = 'schedule'
      this.editHeatingPointVisible = true
    },
    /**
     * Removes a heating point entry from the heating point list and triggers a close of the dialog
     */
    deleteHeatingPoint(arrIndex) {
      if (arrIndex !== false) {
        this.heatingPoints.splice(arrIndex, 1)
      }
      this.hideEditHeatingPoint()
    },
    /**
     * Closes the edit dialog for heating points
     */
    hideEditHeatingPoint() {
      this.title = this.$t('add-heating-mode-dialog.title')
      this.titleIcon = 'thermostat_auto'
      this.selectedHeatingPoint = {}
      this.editHeatingPointVisible = false
    },
    /**
     * Closes the dialog.
     */
    close() {
      this.$root.bisadialog.toggle('addHeatingMode')
    },

    /**
     * Starts save process
     */
    startSaveProcess() {
      this.saveCircle = true
      if (!this.data.gatewayMac) {
        this.$root.bisatoast.error({message: this.$t('heating-mode-dialog.create-error.missing-gatewaymac')})
        this.$root.bisadialog.toggle('addHeatingMode')
      } else if (this.newHeatingPlan) {
        this.prepareTimeSchedules()
      } else {
        this.deleteHeatingPlanFromGW(true)
      }
    },
    /**
     * Prepares time schedule and heating device data for the heatingplan/timeschedules post request
     */
    prepareTimeSchedules() {
      this.loadingValue = 25
      let requestWeekdays = [
        this.weekdays[6].active,
        this.weekdays[0].active,
        this.weekdays[1].active,
        this.weekdays[2].active,
        this.weekdays[3].active,
        this.weekdays[4].active,
        this.weekdays[5].active
      ]

      this.heatingPoints.forEach((heatingPoint) => {
        heatingPoint.weekdays = requestWeekdays
        heatingPoint.active = this.heatingModeActive
        heatingPoint.timezone = moment.tz.guess()
        heatingPoint.deviceIds = this.getHeatingDeviceIds()

        this.timeScheduleQueue.push({
          'attributes': heatingPoint
        })
      })
      this.loadingValue = 33
      this.queueTimeSchedules()
    },
    getHeatingDeviceIds() {
      let devices = [];
      this.heatingDevices.forEach((device) => {
        if (device.active) {
          devices.push(
              device.id
          )
        }
      })

      return devices;
    },
    /**
     * Queues time schedule requests and triggers saving. In case that all time schedules are saved it triggers the storing of a heating plan.
     */
    queueTimeSchedules() {
      if (this.timeScheduleQueue.length > 0) {
        this.saveTimeSchedule(this.timeScheduleQueue[0].attributes)
      } else {
        this.saveHeatingPlan()
      }
    },
    /**
     * Saves a given time schedule and removes it from the queue after success.
     * @param attributes
     */
    saveTimeSchedule(attributes) {
      delete attributes.id
      delete attributes.devices
      this.$rhRequest.sendPost({
        endpoint: "heatingplan/timeschedules",
        data: {
          data: {
            type: "timeschedules",
            "attributes": attributes,
          }
        }
      }, (resp) => {
        this.timeScheduleIds.push({
          "type": 'timeschedules',
          "id": resp?.data?.data?.id
        })
        this.timeScheduleQueue.splice(0, 1)
        this.queueTimeSchedules()
      })
    },
    /**
     * Saves a heating plan and closes the window
     */
    saveHeatingPlan() {
      this.loadingValue = 66
      this.$rhRequest.sendPost({
        endpoint: "heatingplan/heatingplans",
        data: {
          data: {
            "type": "heatingplans",
            "attributes": {
              "gateway": this.data.gatewayMac,
              "name": this.modeName,
              "color": this.heatingModeColor
            },
            "relationships": {
              "timeScheduleList": {
                "data": this.timeScheduleIds
              }
            }
          }
        }
      }, (resp) => {
        let param = resp?.data?.data?.id
        this.pushHeatingPlanToGW(param)
      })
    },
    /**
     * This function pushes the heating plan to gateway
     * Empty data object is necessary for iOS http-plugin
     *
     * @param heatingPlanId
     */
    pushHeatingPlanToGW(heatingPlanId) {
      this.loadingValue = 90
      this.$rhRequest.sendPost({
            endpoint: "ham-service/heatingplan/" + heatingPlanId,
            data: {
              deviceIds: this.getHeatingDeviceIds()
            }
          }, () => {
            this.loadingValue = 100
            this.getDevices()
            setTimeout(() => {
              this.saveCircle = false
              this.$root.bisadialog.toggle('addHeatingMode')
            }, 800);
          },
          () => {
            // close dialog anyway, otherwise user gets stuck in never ending loading overlay
            this.saveCircle = false
            this.$root.bisadialog.toggle('addHeatingMode')
          })
    },
    toggleDeviceActivity(index) {
      this.$set(this.heatingDevices[index], 'active', this.heatingDevices[index].active)
      this.deviceSelectionIsEmpty = !this.checkDeviceSelection()
    },
    activateDay(key) {
      this.weekdays[key].active = !this.weekdays[key].active
    },
    /**
     * This function formats a given hour and minute to a valid time
     */
    formatTime(hour, minute) {
      return moment(hour + ':' + minute, 'HH:mm').format("HH:mm")
    },
    /**
     * Returns a color for a given temperature
     */
    returnTemperatureColor(temperature) {
      let color = 'primary'
      switch (temperature) {
        case 12:
          color = '#8DDBFF'
          break;
        case 16:
          color = '#003953'
          break;
        case 18:
          color = '#67EB9A'
          break;
        case 22:
          color = '#FA9657'
          break;
      }
      return color
    },
    /**
     * Checks if any device is selected or not
     * @returns {boolean}
     */
    checkDeviceSelection() {
      let check = false

      this.heatingDevices.forEach((device) => {
        if (device.active) {
          check = true
          return check
        }
      })
      return check
    },
    /**
     * Loads all heating devices which could be used for a heating mode
     */
    getDevices() {
      this.$rhRequest.sendGet({
        endpoint: "devices/get?includePropertyMetaData=1&deviceTypes=heating,eur_comet,floor-heating,rmt-heating",
      }, (resp) => {
        this.heatingDevices = Object.values(resp?.data?.data)
        this.heatingDevices.forEach((device) => {
          let deviceIsSelected = this.preSelectedDevices.find(selectedDevice => selectedDevice === device?.id)
          if (this.newHeatingPlan || !!deviceIsSelected) {
            device.active = true
          } else {
            device.active = false
          }
        })
        this.deviceSelectionIsEmpty = !this.checkDeviceSelection()

        this.loading = false
      })
    },
    /**
     * Changes the color of the heating plan
     * @param newColor
     */
    changeHeatingModeColor(newColor) {
      this.heatingModeColor = newColor
    },
    startDeleteProcess() {
      this.loadingValue = 25
      this.isDelete = true
      this.saveCircle = true

      if (this.newHeatingPlan) {
        this.$root.bisadialog.toggle('addHeatingMode')
      } else {
        this.deleteHeatingPlanFromGW()
      }
    },
    deleteTimeSchedules(isUpdate = false) {
      if (this.isDelete) this.loadingValue = 95
      if (this.storedTimeScheduleIds.length > 0) {
        this.deleteTimeSchedule(isUpdate)
      } else {
        if (isUpdate) {
          this.prepareTimeSchedules()
        } else {
          if (this.isDelete) this.loadingValue = 100
          this.isDelete = false
          this.$root.bisatoast.success({message: this.$t('heating-mode-dialog.delete.success')})
          this.$root.bisadialog.toggle('addHeatingMode')
        }
      }
    },
    deleteTimeSchedule(isUpdate) {
      this.$rhRequest.sendDelete({
        endpoint: 'heatingplan/timeschedules/' + this.storedTimeScheduleIds[0]
      }, () => {
        this.storedTimeScheduleIds.splice(0, 1)
        this.deleteTimeSchedules(isUpdate)
      })
    },
    deleteHeatingPlan(isUpdate) {
      this.$rhRequest.sendDelete({
        endpoint: 'heatingplan/heatingplans/' + this.heatingPlanId
      }, () => {
        if (this.storedTimeScheduleIds.length > 0) {
          this.deleteTimeSchedule(isUpdate)
        }
      })
    },
    deleteHeatingPlanFromGW(isUpdate = false) {
      this.$rhRequest.sendDelete({
        endpoint: "ham-service/heatingplan/" + this.heatingPlanId
      }, () => {
        if (this.isDelete) this.loadingValue = 60
        this.deleteHeatingPlan(isUpdate)
      })
    },
  }
};
</script>

<style lang="scss">
@import '~@/styles/dialogs/heating-mode.scss';
</style>
