<template v-slot="{ dense }">
  <!-- single block dense view -->
  <div v-if="dense && singleBlock"
       class="subdevices px-4">
    <slot :dense="true"/>
  </div>

  <!-- normal dense view -->
  <div v-else-if="dense"
       class="subdevices">
    <v-tabs optional icons-and-text show-arrows
            slider-size="0">
      <slot :dense="true"/>
    </v-tabs>
  </div>

  <!-- large view -->
  <v-list v-else class="pa-0">
    <slot/>
  </v-list>
</template>

<script>
export default {
  name: 'SubDevices',

  props: {
    dense: Boolean,
    singleBlock: Boolean
  }
}
</script>

<style lang="scss">
@import '~@/styles/device.scss';
</style>
