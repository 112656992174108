<template>
  <div>
    <v-list color="transparent">
      <v-list-item class="px-0">
        <v-list-item-content>
          <div class="font-size-04 mb-2 primary--text font-weight-bold">
            {{ $t('add-device-dialog.step-success.title') }}
          </div>
          <div class="font-size-03">
            {{ $t('add-device-dialog.step-success.description') }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card v-if="typeof device !== 'undefined'">
      <span v-if="isFavourable"
            class="float-right mr-2 mt-2"
            @click="toggleFavorites">
        <v-icon v-if="addToFav"
                color="primary">
          favorite
        </v-icon>
        <span v-else
              style="font-size: 24px"
              class="mt-0 mr-0 material-symbols-outlined">
          favorite
        </span>
      </span>
      <v-card-text>
        <v-flex class="d-flex justify-start mb-6">
          <div class="mr-2">
            <v-icon class="material-icons-outlined" color="primary" v-if="isIcon(device?.type)">
              {{ getIcon(device?.type) }}
            </v-icon>
            <span v-else class="material-symbols-outlined primary--text">{{ getIcon(device?.type) }}</span>
          </div>
          <div>
            <div class="primary--text font-weight-bold font-size-03" v-if="!edit">
              {{ deviceName }}
              <v-icon class="material-icons-outlined" color="primary" @click="startEdit">
                edit
              </v-icon>
            </div>
            <div class="d-flex justify-start" v-else>
              <v-text-field
                  class="font-size-03 pa-1"
                  v-model="deviceName"
                  dense
                  hide-details
                  filled
              ></v-text-field>
              <v-icon class="material-icons-outlined" color="primary" @click="saveName">
                check
              </v-icon>
            </div>
            <div class="font-size-02">
              {{ device?.typeLabel }}
            </div>
          </div>
        </v-flex>
      </v-card-text>
    </v-card>
    <device-alerts class="mt-3" :device="device" :is-gateway-online="true" :is-site-admin="true" :show-info="true" @updatedAlert="updateDeviceAlert"></device-alerts>
  </div>
</template>

<script>
import deviceTypeIcons from "@/config/deviceTypeIcons.json";
import DeviceAlerts from "@/templates/components/devices/DeviceAlerts.vue";

export default {
  name: "AddDeviceStepSuccess",
  components: {DeviceAlerts},
  props: ['device', 'isFavourable'],

  data() {
    return {
      editName: null,
      addToFav: true,
      edit: false
    }
  },

  methods: {
    /**
     * triggers the toggle of the state of addToFavorite in the parent component
     */
    toggleFavorites() {
      this.addToFav = !this.addToFav
      this.$emit('addToFav')
    },
    /**
     * returns if it's md-icon or md-symbol for a passed device type
     *
     * @param type
     * @returns {boolean}
     */
    isIcon(type) {
      return deviceTypeIcons[type].type === 'md-icon'
    },
    /**
     * returns a matching icon for a passed device type
     *
     * @param type
     * @returns {string}
     */
    getIcon(type) {
      return deviceTypeIcons[type].icon ?? 'widgets'
    },

    startEdit() {
      this.deviceName = this.device?.name
      this.edit = true
    },

    /**
     * updates the device name
     */
    saveName() {
      if (!/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/.test(this.deviceName)) {
        this.$rhRequest.sendPost({
          endpoint: 'device/' + this.device['encryptedId'] + '/update',
          data: {
            name: this.deviceName
          }
        }, (resp) => {
          let device = resp.data.data
          if (device?.name) {
            this.deviceName = device.name
            this.editName = device.name
          }
          this.edit = false
        }, (err) => {
          console.error(err)
          this.$root.bisatoast.error({message: this.$t('app.generic-error')})
          this.edit = false
        })
      } else {
        this.$root.bisatoast.error({message: this.$t('device-dialog.update-name.invalid-chars')})
      }
    },
    updateDeviceAlert(alertName) {
      this.$emit('updatedAlert', alertName)
    }
  },
  computed: {
    deviceName: {
      get() {
        if (this.editName !== null) {
          return this.editName
        } else {
          return this.device?.name
        }
      },
      set(value) {
        this.editName = value
      }
    }
  }
}
</script>

