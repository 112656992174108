<!-- summary text for a user configured HAM condition -->

<template>
  <span v-if="condition?.timerType === 'SINGULAR'">
    {{
      $t('condition-summary-card.timer.singular', {
        startTime: formatDate(condition.startTime),
        triggerTime: condition.triggerTime,
        weekdays: getWeekdayString(condition.weekdays)
      })
    }}
  </span>
  <span v-else-if="condition?.timerType === 'DAILY'">
    {{ $t('condition-summary-card.timer.daily', {triggerTime: condition.triggerTime}) }}
  </span>
  <span v-else-if="condition?.timerType === 'WEEKLY'">
    {{
      $t('condition-summary-card.timer.weekly', {
        weekdays: getWeekdayString(condition.weekdays),
        triggerTime: condition.triggerTime
      })
    }}
  </span>
  <span v-else-if="condition?.timerType === 'INTERVAL'">
    {{
      $t('condition-summary-card.timer.interval', {
        startTime: formatDate(condition.startTime.split(' ')[0]),
        triggerTime: condition.startTime.split(' ')[1],
        timeInterval: condition.timeInterval
      })
    }}
  </span>
  <span v-else-if="condition?.type === 'properties'">
    {{ condition.metaData.device?.name }}: {{ itemTitle(condition?.property) }}
    <span v-if="condition?.constraint==='<'" v-text="$t('condition-summary.compare-operator.lt')"/>
    <span v-if="condition?.constraint==='>'" v-text="$t('condition-summary.compare-operator.gt')"/>
    <span v-if="condition?.constraint==='='" v-text="$t('condition-summary.compare-operator.eq')"/>
    {{ valueText }} {{ unitOf(condition?.property) }}
  </span>
  <span v-else>
    -
  </span>
  <!-- add cases for future condition types here -->
</template>

<script>
import formats from "@/scripts/formats";
import deviceProperties from "@/config/deviceProperties.json"

export default {
  name: 'ConditionSummary',

  props: ['condition'],

  computed: {
    valueText() {
      if (typeof this.condition?.value == "boolean") {
        return this.stateLabel(this.condition.property, this.condition?.value)
      } else if (deviceProperties[this.condition?.property]?.valueLabelMapping) {
        return this.$t(deviceProperties[this.condition.property].valueLabelMapping[this.condition.value])
      } else {
          return this.condition?.value
      }
    }
  },

  methods: {
    /**
     * returns a string with abbreviations for the selected weekdays
     * @param weekdays
     * @returns {string}
     */
    getWeekdayString(weekdays) {
      let weekdayString = ""
      // dereference array to avoid calling the watcher for weekdays
      let weekdayArray = [...weekdays]
      /*
       * The weekday array in the HAM (ProSyst) expects the first index to be Sunday. So we need to shift the first
       * element and push it to the end of the array.
       */
      let sunday = weekdayArray.shift()
      weekdayArray.push(sunday)
      // add translations for each active weekday
      weekdayArray.forEach((active, i) => {
        if (active) {
          weekdayString += this.$t('app.weekday.two-letter.' + i)
          if (i < 6) {
            weekdayString += ", "
          }
        }
      })
      return weekdayString
    },

    /**
     * returns a formatted string representation for date
     * @param date
     * @returns {string}
     */
    formatDate(date) {
      return formats.formatDateString(date, this.$t('app.date-time-format.date-only'))
    },

    /**
     * returns a translated label for the passed property name
     * @param name
     * @returns {string}
     */
    itemTitle(name) {
      if (this.condition?.metaData?.device?.type === 'meter-mec') {
        return this.$t(deviceProperties[name]?.label.replace('{mec-meter-type}', this.condition?.metaData?.device?.energyDataType))
      } else {
        return this.$t(deviceProperties[name]?.label)
      }
    },

    /**
     * returns a translated status text for properties of type boolean
     * @returns {string}
     */
    stateLabel(name, state) {
      switch (state) {
        case true:
          return this.$t(deviceProperties[name]?.activeLabel)
        case false:
          return this.$t(deviceProperties[name]?.inactiveLabel)
      }
    },

    /**
     * returns the unit of a property
     * @param property
     * @returns {string}
     */
    unitOf(property) {
      return deviceProperties[property]?.unit
    }
  }
}
</script>
