<template>
  <div class="page-content">

    <v-skeleton-loader v-if="loading.accountLinkings || loading.contracts"
                       type="article"
                       height="300"/>

    <div v-else-if="!loading.accountLinkings && contractsData.length > 0">

      <v-row>
        <v-col cols="12" sm="6" lg="4">
          <contract-selection-card :loading="loading.contracts"
                                   :contracts-data="contractsData"
                                   @change-selected-contract="changeSelectedContract"/>
        </v-col>

        <v-col cols="12" sm="6" lg="8">
          <energy-data-visualization-card :loading="loading.contracts"
                                          ref="consumption-graph"
                                          :has-contract-selected="hasContractSelected"
                                          :consumption-points-data="consumptionPointsDropdownData"/>
        </v-col>
      </v-row>

    </div>

    <!-- Empty State -->
    <div v-else>
      <empty-state
        :empty-state-title="emptyStateTitle"
        :empty-state-text="emptyStateText">
        <template v-slot:illustration>
          <energy-empty-state-illustration
            :fill-primary="'var(--v-primary-base)'"
            :fill-secondary="'var(--v-secondary-base)'"
          />
        </template>
      </empty-state>
    </div>

    <v-speed-dial class="pulse">
      <template v-slot:activator>
        <v-btn v-if="showAddButton" fab dark large color="primary" @click="openAccountLinkingDialog" fixed right bottom>
          <v-icon dark>add</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>
  </div>
</template>

<script>

import EmptyState from "@/templates/components/emptyStates/EmptyState.vue";
import EnergyEmptyStateIllustration from "@/templates/components/emptyStates/svg/EnergyEmptyStateIllustration.vue";
import ContractSelectionCard from "@/templates/components/energy/ContractSelectionCard.vue";
import EnergyDataVisualizationCard from "@/templates/components/energy/EnergyDataVisualizationCard.vue";
import moment from "moment/moment";

export default {
  name: 'EnergyPage',

  components: {EnergyDataVisualizationCard, ContractSelectionCard, EnergyEmptyStateIllustration, EmptyState},

  props: [
    'bottomNavigation'
  ],

  data: () => ({
    loading: {
      accountLinkings: true,
      contracts: false,
      consumptions: false
    },
    hasAccountLinked: false,
    hasContractSelected: false,
    contractsData: [],
    consumptionPointsData: [],
    availableConsumptionPointsData: [],
    consumptionPointsDropdownData: [],
    showAddButton: true
  }),
  methods: {
    getEnergylineAccountLinkings() {
      this.loading.accountLinkings = true
      this.$rhRequest.sendGet({
        endpoint: "consumption-data-service/energyline-account-linkings"
      }, (response) => {
        if (response.data?.data?.length > 0) {
          this.hasAccountLinked = true
          this.getContracts()
        }
        this.loading.accountLinkings = false
      }, (error) => {
        console.error(error)
        this.loading.accountLinkings = false
      })
    },
    getContracts() {
      this.loading.contracts = true
      this.$rhRequest.sendGet({
        endpoint: "consumption-data-service/contracts"
      }, (response) => {
        response.data?.data?.forEach((contract) => {
          // only show contracts which are not disabled AND are valid
          if ((contract.disabledSince === null || moment(contract.disabledSince).diff(moment.now()) > 0)
            && (contract.validUntil === null || moment(contract.validUntil).diff(moment().add(6, 'M')) > 0)
          ) {
            this.contractsData.push(contract)
          }

          contract.consumptionPoints?.forEach((consumptionPoint) => {
            if (consumptionPoint.validUntil === null || moment(consumptionPoint.validUntil).diff(moment().add(6, 'M')) > 0) {
              this.consumptionPointsData.push(consumptionPoint)
            }
          })
        })

        this.loading.contracts = false
      }, (error) => {
        console.error(error)
        this.loading.contracts = false
      })
    },
    openAccountLinkingDialog() {
      this.showAddButton = false
      this.$root.bisadialog.toggle('energylineAccountLinking')

      // listen for close on account linking dialog close to reload energy page with possibly new empty state
      this.$root.$on('account-linking-dialog-close', () => {
        this.getEnergylineAccountLinkings()
        this.showAddButton = true
        this.$root.$off('account-linking-dialog-close')
      })
    },
    // when user selects contract, filter all consumption points for that contract's consumption points
    changeSelectedContract(selectedContract) {
      this.hasContractSelected = false
      this.availableConsumptionPointsData = []
      this.consumptionPointsDropdownData = []
      if (selectedContract !== null) {
        this.hasContractSelected = true
        selectedContract.consumptionPoints?.forEach(consumptionPointRelationship => {
          if (this.consumptionPointsData.length > 0) {
            let found = this.consumptionPointsData.find(x => x.id === consumptionPointRelationship.id)
            this.availableConsumptionPointsData.push(found)
          }
        })
        this.groupConsumptionPoints()
      }
      this.$refs["consumption-graph"].resetGraphData()
      this.$refs["consumption-graph"].resetTrendData()
    },
    groupConsumptionPoints() {
      // group consumptionPoints by their consumptionType
      // result: { consumptionType1: Array containing all cP with cT1, consumptionType2: Array containing all cP with cT2, ... }
      this.availableConsumptionPointsData = this.availableConsumptionPointsData.reduce((acc, obj) => {
        const key = obj.consumptionType;
        const curGroup = acc[key] ?? [];
        return {...acc, [key]: [...curGroup, obj]};
      }, {});

      // add header for each consumption type and add respective consumptionPoints below said header
      Object.keys(this.availableConsumptionPointsData).forEach((consumptionType) => {
        // add header for consumptionType
        this.consumptionPointsDropdownData.push({header: this.$t('energy-data-visualization-card.consumption-point-selection.header-' + consumptionType.toLowerCase())})
        // add all consumptionTypes for previously pushed consumptionType Header
        this.availableConsumptionPointsData[consumptionType].forEach((item) => this.consumptionPointsDropdownData.push(item))
        // add divider between consumptionTypes for better overview
        this.consumptionPointsDropdownData.push({divider: true})
      })
      // remove last divider
      this.consumptionPointsDropdownData.pop()
    }
  },

  mounted() {
    this.$showFirstLaunchDialog()
    this.getEnergylineAccountLinkings()
  },
  computed: {
    emptyStateTitle() {
      return this.hasAccountLinked ? this.$t('energy-page.emptyState.has-linked.title').toString() : this.$t('energy-page.emptyState.title').toString();
    },
    emptyStateText() {
      return this.hasAccountLinked ? this.$t('energy-page.emptyState.has-linked.text').toString() : this.$t('energy-page.emptyState.text').toString();
    },
  }
}
</script>
