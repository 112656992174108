<template>
  <v-card elevation="0">
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col v-for="(item, index) in selectionOptions" v-bind:key="index" cols="6" justify="center" align="center">
          <v-btn justify="center" x-large icon tile :color="getColor(item.value)" :disabled="!canChange"
                 :loading="loadingButton === item.value" @click="setState(item.value)">
            <span class="material-symbols-outlined v-icon ma-0">{{ item.icon }}</span>
          </v-btn>
          <br>
          <span>{{ item.name }}</span>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "DoubleSwitchInput",
  props: {
    device: Object,
    actuator: Object,
    dense: Boolean,
    updateOnChange: {
      type: Boolean,
      default: true
    },
    isSelectionForRule: {
      type: Boolean,
      default: false
    },
    value: String
  },
  data() {
    return {
      selectionOptions: [
        {
          value: 1,
          name: this.$t('double-smart-module.state.1'),
          icon: "looks_one"
        },
        {
          value: 2,
          name: this.$t('double-smart-module.state.2'),
          icon: "looks_two"
        },
        {
          value: 3,
          name: this.$t('double-smart-module.state.3'),
          icon: "looks_3"
        },
        {
          value: 4,
          name: this.$t('double-smart-module.state.4'),
          icon: "looks_4"
        }
      ],
      // Copy for faster change if State is updated
      deviceCopy: this.device,
      loadingButton: 0,
      allowValueChange: true
    }
  },
  methods: {
    getColor(value) {
      if (this.isSelectionForRule) {
        return this.value === value ? 'green' : null
      }
      return this.deviceCopy.value === value ? 'green' : null
    },
    setState(value) {
      if (this.isSelectionForRule) {
        this.$emit('input', value)
        return
      }
      if (!this.updateOnChange) return;
      this.loadingButton = value
      this.allowValueChange = false
      this.$rhRequest.sendPost({
        endpoint: 'devices/update-property',
        data: {
          deviceId: this.deviceCopy.id,
          value: value
        }
      }, () => {
        this.$emit('updateDevice')
        this.deviceCopy.value = value
        this.loadingButton = 0
        this.allowValueChange = true
      }, (error) => {
        this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        console.error(error)
        this.loadingButton = 0
        this.allowValueChange = true
      })
    }
  },
  computed: {
    canChange() {
      if (this.isSelectionForRule) {
        return this.allowValueChange
      }
      return this.deviceCopy?.online && this.deviceCopy?.gatewayOnline && this.allowValueChange
    }
  }
}
</script>