<template>
  <v-expansion-panels class="sg-ready-advanced-setting" v-model="openPanel">
    <v-expansion-panel :key="0">
      <v-expansion-panel-header color="tileBackground" class="px-4 font-weight-bold sg-ready-expansion-panel-header">
        {{ $t('sg-ready.advanced-settings.title') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content class="sg-ready-expansion-panel" color="tileBackground">
        <v-simple-table>
          <template v-slot:default>
            <tbody class="tileBackground">
            <tr v-for="(item, index) in listItems" v-bind:key="index" @click="clickItem(item.state)">
              <td class="pl-0">
                <v-list-item>
                  <v-list-item class="px-0 sg-ready-rules-list-item straight">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle :color="textColor">
                        {{ $t('sg-ready-advance-settings.summary.' + item.powerDirection, {value: getValue(item.state)}) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item>
              </td>
              <td>
                <v-switch class="mt-0" inset hide-details :loading="loading === item.state" v-model="item.active" @click.stop="clickSwitch(item.state)"
                          v-if="hasSmartMeter"></v-switch>
                <v-icon class="material-icons-outlined"
                        @click="showInfoNoSmartMeter"
                        v-else
                        color="primary"
                        left>info
                </v-icon>
              </td>
              <td @click="clickItem(item.state)">
                <v-icon color="primary">chevron_right</v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "SGReadyAdvancedSettings",
  props: ['device', 'smartMeters', 'sgReadyRules'],
  data() {
    return {
      openPanel: 0,
      listItems: [
        {
          title: this.$t('double-smart-module.state.3'),
          value: this.getValue(3),
          state: 3,
          powerDirection: this.getProperty(3),
          action: this.getAction(3),
          condition: this.getCondition(3),
          rule: this.getRule(3),
          active: this.isActive(3)
        },
        {
          title: this.$t('double-smart-module.state.4'),
          value: this.getValue(4),
          state: 4,
          powerDirection: this.getProperty(4),
          action: this.getAction(4),
          condition: this.getCondition(4),
          rule: this.getRule(4),
          active: this.isActive(4)
        },
        {
          title: this.$t('double-smart-module.state.1'),
          value: this.getValue(1),
          state: 1,
          powerDirection: this.getProperty(1),
          action: this.getAction(1),
          condition: this.getCondition(1),
          rule: this.getRule(1),
          active: this.isActive(1)
        }
      ],
      loading: 0
    }
  },
  methods: {
    clickItem(state) {
      if (!this.hasSmartMeter) {
        this.showInfoNoSmartMeter()
        return
      }
      this.$root.bisadialog.toggle('sgReady', true, {
        action: this.getAction(state),
        rule: this.getRule(state),
        mecMeters: this.smartMeters,
        value: this.getValue(state),
        device: this.device
      })
    },
    clickSwitch(state) {
      let condition = this.getCondition(state)
      if (condition) {
        this.saveRule(state)
      } else {
        let item = this.getItem(state)
        if (!item) {
          console.error('No item found for state', state)
          return
        }
        item.active = false
        this.clickItem(state)
      }
    },
    showInfoNoSmartMeter() {
      this.$root.bisadialog.toggle('infoText', true, {
        title: this.$t('sg-ready-advances-settings.no-smart-meter-info.title'),
        icon: 'info',
        html: this.$t('sg-ready-advances-settings.no-smart-meter-info.text')
      })
    },
    // Method to get rule, for state, if exists
    // Comparison state in rule.attributes.condition.value
    getRule(state) {
      if (!this.sgReadyRules) {
        return null
      }
      return this.sgReadyRules.find(rule => rule.attributes.action.state === state)
    },
    // Method to get value from rule
    getValue(state) {
      const standardValues = {
        '1': 5000,
        '3': 2500,
        '4': 3000,
      }
      const rule = this.getRule(state)
      if (rule) {
        return rule.attributes.condition.value
      }
      return standardValues[state]
    },
    getAction(state) {
      return {
        deviceId: this.device.id,
        state: state,
        type: 'sgready'
      }
    },
    getProperty(state) {
      if (state === 1) {
        return 'consumption-current'
      } else {
        return 'feed-current'
      }
    },
    getCondition(state) {
      let rule = this.getRule(state)
      if (rule) {
        return rule.attributes.condition
      }
      if (this.smartMeters.length === 1) {
        let mecMeter = this.smartMeters[0]
        return {
          deviceId: mecMeter.id,
          property: this.getProperty(state),
          constraint: '>',
          value: this.getValue(state),
          type: 'properties'
        }
      }
      return null
    },
    isActive(state) {
      let rule = this.getRule(state)
      if (rule) {
        return !rule.attributes.deactivated
      } else {
        return false
      }
    },
    getItem(state) {
      let item = null
      for (let listItem of this.listItems) {
        if (listItem.state === state) {
          item = listItem
        }
      }
      return item
    },
    saveRule(state) {
      let item = this.getItem(state)
      if (!item) {
        console.error('No item found for state', state)
        return
      }
      this.save(item)
    },
    save(item) {
      this.loading = item.state
      if (!item.condition) {
        console.error('No condition')
        this.loading = 0
        item.active = false
        return
      }
      // clientId and siteId will be added by the proxy (PHP)
      let rule = {
        name: '',
        iconKey: '',
        deactivated: !item.active,
        condition: item.condition,
        action: item.action,
        synced: 'PENDING',
        hidden: true
      }

      if (item.rule?.id) {
        // update existing rule
        this.$rhRequest.sendPost({
          endpoint: "rule/update",
          data: rule,
          params: {id: item.rule.id}
        }, (resp) => {
          if (resp.data.code === 0) {
            this.$root.bisatoast.success({
              message: this.$t('sg-advanced-settings.save.rule-updated'),
              showCloseBtn: true
            })
            // notifies RulesCard to reload the rule items
            item.rule = resp.data.data
            this.$root.$emit('reloadHomeEnergySGReady')
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
          this.loading = 0
        }, (err) => {
          console.error(err)
          this.loading = 0
          item.active = !item.active
          if (err.response.data.code === 580) {
            this.$root.bisatoast.warning({message: this.$t('add-rule-dialog.save.gateway-offline'), showCloseBtn: true})
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
          // notifies RulesCard to reload the rule items
          this.$root.$emit('reloadHomeEnergySGReady')
        })
      } else {
        // create new rule
        this.$rhRequest.sendPost({
          endpoint: "rule/add",
          data: rule
        }, (resp) => {
          if (resp.data.code === 0) {
            this.$root.bisatoast.success({
              message: this.$t('sg-advanced-settings.save.rule-created'),
              showCloseBtn: true
            })
            // notifies RulesCard to reload the rule items
            this.$root.$emit('reloadHomeEnergySGReady')
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
          this.loading = 0
        }, (err) => {
          console.error(err)
          this.loading = 0
          if (err.response.data.code === 580) {
            this.$root.bisatoast.warning({message: this.$t('add-rule-dialog.save.gateway-offline'), showCloseBtn: true})
            //this.$root.bisadialog.toggle('addRule')
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
          // notifies RulesCard to reload the rule items
          this.$root.$emit('reloadHomeEnergySGReady')
        })
      }
    },
  },
  computed: {
    textColor() {
      return this.hasSmartMeter ? 'primary' : 'grey'
    },
    hasSmartMeter() {
      return this.smartMeters.length > 0
    }
  },
  watch: {
    // Watch SG Ready Rules
    sgReadyRules: function (newRules) {
      if (newRules) {
        for (let item of this.listItems) {
          item.rule = this.getRule(item.state)
          item.active = this.isActive(item.state)
          item.condition = this.getCondition(item.state)
          item.action = this.getAction(item.state)
          item.value = this.getValue(item.state)
        }
      }
    },
    device: function (newDevice) {
      if (newDevice) {
        for (let item of this.listItems) {
          item.rule = this.getRule(item.state)
          item.active = this.isActive(item.state)
          item.condition = this.getCondition(item.state)
          item.action = this.getAction(item.state)
          item.value = this.getValue(item.state)
        }
      }
    },
    smartMeters: function (newSmartMeters) {
      if (newSmartMeters) {
        for (let item of this.listItems) {
          item.rule = this.getRule(item.state)
          item.active = this.isActive(item.state)
          item.condition = this.getCondition(item.state)
          item.action = this.getAction(item.state)
          item.value = this.getValue(item.state)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/sg-ready-home-energy.scss';
</style>