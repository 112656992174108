<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       width="100%" viewBox="0 0 593 128" enable-background="new 0 0 593 128" xml:space="preserve">
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
      d="
M170.000000,1.000001
	C176.687637,1.000000 183.375290,1.000000 190.530533,1.408540
	C190.802963,2.933661 190.738632,4.092656 190.392517,5.160309
	C186.643936,16.724127 182.842758,28.270876 179.072144,39.827572
	C171.878235,61.876564 164.619537,83.904892 157.548553,105.993271
	C155.118866,113.583168 153.169052,121.326675 151.000000,129.000000
	C144.312363,129.000000 137.624710,129.000000 130.458832,128.612900
	C118.972862,94.660851 107.965141,61.095894 96.665352,26.640368
	C89.222504,49.669792 82.060226,71.689629 74.997604,93.741379
	C71.242607,105.465706 67.660271,117.245331 64.000000,129.000000
	C57.312355,129.000000 50.624710,129.000000 43.466541,128.602020
	C39.072319,115.989784 35.161572,103.771324 31.222345,91.562065
	C21.791674,62.332573 12.354228,33.105251 2.847835,3.900387
	C2.700490,3.447725 1.636312,3.293499 1.000000,3.000000
	C1.641840,2.333333 2.270795,1.102506 2.927520,1.087507
	C9.303164,0.941889 15.683464,1.000000 22.526276,1.398105
	C32.934326,32.663673 42.878532,63.531139 52.822739,94.398605
	C53.214428,94.325569 53.606113,94.252533 53.997803,94.179497
	C63.998535,63.119663 73.999268,32.059834 84.000000,1.000000
	C92.354233,1.000000 100.708466,1.000000 109.219208,1.312786
	C119.415527,32.467258 129.455338,63.308945 139.495148,94.150635
	C148.422302,69.965790 156.292374,46.074097 163.998276,22.129562
	C166.239975,15.163969 168.012451,8.047379 170.000000,1.000001
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M350.468262,129.000000
	C350.000061,86.645775 350.000153,44.291542 350.000122,1.468657
	C356.021149,1.000000 362.042297,1.000000 368.531555,1.468657
	C368.999664,18.452259 368.999664,34.967205 368.999664,52.355568
	C386.009949,35.379002 402.167297,19.265900 418.281738,3.109977
	C418.752808,2.637699 418.770782,1.713498 419.000000,1.000000
	C428.020905,1.000000 437.041779,1.000000 446.531342,1.000001
	C446.041260,2.458102 445.298096,4.130957 444.091431,5.342031
	C426.479492,23.018215 408.805695,40.632740 390.899536,58.508694
	C395.449890,63.701584 399.800903,68.823380 404.318665,73.793594
	C418.403564,89.289070 432.578186,104.703018 446.648285,120.211823
	C448.941956,122.739960 450.881836,125.589035 452.992615,128.644897
	C444.312439,129.000000 435.624878,129.000000 426.825012,128.781586
	C426.155823,127.565941 425.763275,126.420029 425.016602,125.594528
	C417.837128,117.657555 410.531891,109.833740 403.394714,101.859337
	C396.384674,94.026924 389.591888,86.000046 382.580048,78.169273
	C378.383667,73.482826 373.949768,69.009033 369.000000,63.782146
	C369.000000,86.195198 369.000000,107.597595 369.000000,129.000000
	C362.978851,129.000000 356.957703,129.000000 350.468262,129.000000
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M463.468567,129.000000
	C468.030151,116.874275 472.988220,104.718040 478.106537,92.629646
	C486.714325,72.299950 495.415466,52.009762 504.094269,31.710180
	C508.365692,21.719296 512.675049,11.744628 516.983643,1.381307
	C524.020935,1.000000 531.041931,1.000000 538.532104,1.386548
	C541.358337,7.634379 543.582336,13.553451 546.098022,19.345850
	C552.949890,35.122658 560.007690,50.810200 566.830078,66.599594
	C575.004395,85.517990 582.994568,104.515923 591.168701,123.434380
	C591.628540,124.498688 593.034424,125.154221 594.000000,126.000000
	C594.000000,126.982140 594.000000,127.964287 594.000000,129.000000
	C586.979248,129.000000 579.958435,129.000000 572.460938,128.625610
	C557.517029,94.601364 543.049744,60.951519 528.198425,26.408241
	C526.990479,29.255352 526.129333,31.312830 525.246765,33.361099
	C513.667053,60.236481 502.043945,87.093323 490.539154,114.000725
	C488.446442,118.895172 486.834106,123.994987 485.000000,129.000000
	C477.979034,129.000000 470.958099,129.000000 463.468567,129.000000
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M272.531738,1.000000
	C273.000000,37.097721 273.000000,73.195442 273.000000,109.799591
	C293.610565,109.799591 313.981415,109.799591 335.000366,109.799591
	C335.000366,116.213219 335.000366,122.138176 335.000183,128.531570
	C308.312439,129.000000 281.624878,129.000000 254.468658,128.531342
	C254.000000,85.708458 254.000000,43.354229 254.000000,1.000000
	C260.021149,1.000000 266.042297,1.000000 272.531738,1.000000
z"/>
    <path :fill="fillColor"  opacity="1.000000" stroke="none"
          d="
M228.531723,1.000000
	C229.000259,43.354229 229.000504,85.708458 229.000366,128.531342
	C222.978851,129.000000 216.957703,129.000000 210.468277,129.000000
	C210.000107,86.645775 210.000214,44.291542 210.000168,1.468657
	C216.021149,1.000000 222.042297,1.000000 228.531723,1.000000
z"/>
</svg>
</template>

<script>
export default {
  name: 'MainLogo',
  props: {
    fillColor: {
      type: String,
    },
  }
}
</script>
