<template>
  <v-stepper-content step="4"
                     class=" pb-2 pa-1">

    <div v-if="timerType === timerTypes.SINGULAR"
         v-html="$t('add-rule-step-timer-definition.singular.info-text')"/>
    <div v-if="timerType === timerTypes.DAILY"
         v-html="$t('add-rule-step-timer-definition.daily.info-text')"/>
    <div v-if="timerType === timerTypes.WEEKLY"
         v-html="$t('add-rule-step-timer-definition.weekly.info-text')"/>
    <div v-if="timerType === timerTypes.INTERVAL"
         v-html="$t('add-rule-step-timer-definition.interval.info-text')"/>

    <content-card v-if="timerType === timerTypes.SINGULAR"
                  :title="$t('add-rule-dialog.singular-timer.title').toString()"
                  icon="today"
                  class="my-5">
      <template v-slot:content>
        <div class="pa-5">
          <div v-html="$t('add-rule-dialog.singular-timer.text')"/>

          <v-row>
            <v-col>
              <v-menu v-model="dateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="formattedStartTime"
                                outlined dense hide-details
                                prepend-icon="event"
                                class="my-5"
                                :placeholder="$t('add-rule-step-timer-definition.start-time.label')"
                                readonly
                                v-bind="attrs"
                                v-on="on"/>
                </template>
                <v-date-picker v-model="startTime"
                               no-title
                               scrollable
                               @input="setStartTime"/>
              </v-menu>
            </v-col>

            <v-col>
              <v-menu v-model="timeMenu"
                      ref="timePickerMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y left
                      min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="triggerTime"
                                outlined dense hide-details
                                prepend-icon="schedule"
                                class="my-5"
                                :placeholder="$t('add-rule-step-timer-definition.trigger-time.label')"
                                readonly
                                v-bind="attrs"
                                v-on="on"/>
                </template>
                <v-time-picker v-model="triggerTime"
                               format="24hr"
                               scrollable
                               @click:minute="setTriggerTime(triggerTime)"/>
              </v-menu>
            </v-col>
          </v-row>
        </div>
      </template>
    </content-card>

    <content-card v-else-if="timerType === timerTypes.DAILY"
                  :title="$t('add-rule-dialog.daily-timer.title').toString()"
                  icon="event_repeat"
                  class="my-5">
      <template v-slot:content>
        <div class="px-5 pt-5">
          <div v-html="$t('add-rule-dialog.daily-timer.text')"/>

          <v-row>
            <v-col>
              <v-menu v-model="timeMenu"
                      ref="timePickerMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="triggerTime"
                                outlined dense hide-details
                                prepend-icon="schedule"
                                class="my-5"
                                :placeholder="$t('add-rule-step-timer-definition.trigger-time.label')"
                                readonly
                                v-bind="attrs"
                                v-on="on"/>
                </template>
                <v-time-picker v-model="triggerTime"
                               format="24hr"
                               scrollable
                               @click:minute="setTriggerTime(triggerTime)"/>
              </v-menu>
            </v-col>

            <v-col></v-col>
          </v-row>
        </div>

        <extended-timer-definition v-model="extendedTimerDefinitions"
                                   @change="updateModel"/>
      </template>
    </content-card>

    <content-card v-else-if="timerType === timerTypes.WEEKLY"
                  :title="$t('add-rule-dialog.weekly-timer.title').toString()"
                  icon="calendar_month"
                  class="my-5">
      <template v-slot:content>
        <div class="pa-5">
          <div v-html="$t('add-rule-dialog.weekly-timer.text')"/>

          <v-chip-group class="weekdays"
                        v-model="weekdaySelection"
                        column
                        multiple>
            <v-chip active-class="active"
                    v-for="idx in 7" v-bind:key="idx">
              {{ $t('app.weekday.' + (idx - 1)) }}
            </v-chip>
          </v-chip-group>

          <v-row>
            <v-col>
              <v-menu v-model="timeMenu"
                      ref="timePickerMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="triggerTime"
                                outlined dense hide-details
                                prepend-icon="schedule"
                                class="my-5"
                                :placeholder="$t('add-rule-step-timer-definition.trigger-time.label')"
                                readonly
                                v-bind="attrs"
                                v-on="on"/>
                </template>
                <v-time-picker v-model="triggerTime"
                               format="24hr"
                               scrollable
                               @click:minute="setTriggerTime(triggerTime)"/>
              </v-menu>
            </v-col>

            <v-col></v-col>
          </v-row>
        </div>

        <extended-timer-definition v-model="extendedTimerDefinitions"
                                   @change="updateModel"/>
      </template>
    </content-card>

    <content-card v-else-if="timerType === timerTypes.INTERVAL"
                  :title="$t('add-rule-dialog.interval-timer.title').toString()"
                  icon="timelapse"
                  class="my-5">
      <template v-slot:content>
        <div class="pa-5">
          <div v-html="$t('add-rule-dialog.interval-timer.text')"/>
          <timer-start-time v-model="startTime" @change="updateModel"></timer-start-time>
          <v-row>
            <v-col>
              <v-menu v-model="intervalMenu"
                      ref="intervalPickerMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="timeInterval"
                                outlined dense hide-details
                                prepend-icon="update"
                                class="mb-5"
                                :placeholder="$t('add-rule-step-timer-definition.time-interval.label')"
                                readonly
                                v-bind="attrs"
                                v-on="on"/>
                </template>
                <v-time-picker v-model="timeInterval"
                               format="24hr"
                               scrollable
                               @click:minute="setInterval(timeInterval)"/>
              </v-menu>
            </v-col>

            <v-col></v-col>
          </v-row>
        </div>

        <extended-timer-definition v-model="extendedTimerDefinitions"
                                   @change="updateModel"/>
      </template>
    </content-card>

  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import formats from "@/scripts/formats";
import moment from "moment";
import ExtendedTimerDefinition from "@/templates/dialogs/automations/ExtendedTimerDefinition.vue";
import TimerStartTime from "@/templates/components/living/rules/TimerStartTime.vue";

export default {
  name: 'AddRuleStepTimerDefinition',
  components: {
    TimerStartTime,
    ExtendedTimerDefinition,
    ContentCard
  },

  props: ['timerType', 'value'],

  data: function () {
    return {
      timerTypes: Object.freeze({
        SINGULAR: 'SINGULAR',
        DAILY: 'DAILY',
        WEEKLY: 'WEEKLY',
        INTERVAL: 'INTERVAL'
      }),
      dateMenu: false,
      startTime: null,
      timeMenu: false,
      triggerTime: null,
      weekdaySelection: [0, 1, 2, 3, 4, 5, 6], // default value
      weekdays: [true, true, true, true, true, true, true], // default value
      intervalMenu: false,
      timeInterval: null,
      extendedTimerDefinitions: null
    }
  },

  computed: {
    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      switch (this.timerType) {
        case this.timerTypes.SINGULAR:
          return this.startTime && this.triggerTime
        case this.timerTypes.DAILY:
          return this.triggerTime
        case this.timerTypes.WEEKLY:
          return this.triggerTime
        case this.timerTypes.INTERVAL:
          return this.startTime && this.timeInterval // Trigger Time irrelevant
        default:
          return false
      }
    },

    /**
     * returns localized startTime string
     * @returns {null|string}
     */
    formattedStartTime() {
      if (this.startTime) {
        return formats.formatDateString(this.startTime, this.$t('app.date-time-format.date-only'))
      }
      return null
    }
  },

  methods: {
    setStartTime() {
      this.dateMenu = false
      this.updateModel()
    },

    setTriggerTime(time) {
      this.$refs.timePickerMenu.save(time)
      this.updateModel()
    },

    setInterval(timeInterval) {
      this.$refs.intervalPickerMenu.save(timeInterval)
      this.updateModel()
    },

    /**
     * updates v-model
     */
    updateModel() {
      let data = {
        timerType: this.timerType,
        triggerTime: this.triggerTime + ":00", // add seconds
        weekdays: this.weekdays,
        timeInterval: this.timeInterval + ":00", // add seconds
        repeatCount: this.extendedTimerDefinitions?.repeatCount
      };
      // Start time needed for Interval
      if (this.startTime == null && this.timerType !== this.timerTypes.INTERVAL) {
        this.startTime = moment(new Date()).format("YYYY-MM-DD");
      }
      // Time gets set for Interval
      if (this.timerType !== this.timerTypes.INTERVAL) {
        data.startTime = `${this.startTime} 00:00:00`;
      } else {
        data.startTime = this.startTime
      }
      if (this.extendedTimerDefinitions?.endTime != null) {
        data.endTime = `${this.extendedTimerDefinitions?.endTime} 23:59:59`;
      }

      this.$emit('input', data)
    }
  },

  watch: {
    /**
     * updates the weekdays model
     * @param newValue
     */
    weekdaySelection(newValue) {
      this.weekdays = [false, false, false, false, false, false, false]
      newValue.forEach(i => {
        this.weekdays[i] = true
      })
      /*
       * The weekday array in the HAM (ProSyst) expects the first index to be Sunday. So we need to pop the last element
       * and re-add it at the start of the array.
       */
      let sunday = this.weekdays.pop()
      this.weekdays.unshift(sunday)
      this.updateModel()
    },

    /**
     * reset timer definitions
     */
    timerType() {
      this.startTime = null
      this.triggerTime = null
      this.weekdaySelection = [0, 1, 2, 3, 4, 5, 6]
      this.timeInterval = null
      this.extendedTimerDefinitions = null
    }
  }
};
</script>

<style lang="scss">
.weekdays {
  .active {
    background-color: var(--v-primary-base);
    color: white;
    font-weight: 500;
  }
}
</style>
