<template>
  <v-expansion-panels v-if="device.hasZwaveConfig">
    <v-expansion-panel class="tileBackground">
      <v-expansion-panel-header class="px-5">
        <div>
          <v-icon class="material-icons-outlined non-flip"
                  :color="color">
            integration_instructions
          </v-icon>
          <span class="font-weight-bold font-size-04 mr-4"
                :class="color + '--text'">
          {{ $t('zwave-config.title') }}
        </span>
        </div>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <v-list>
          <v-list-item v-for="(curatedZWaveConfig, index) in device.curatedZWaveConfigs" class="list-item" v-bind:key="index">
            <v-list-item-content>
              <v-list-item-title class="font-size-03">
                <v-icon class="material-icons-outlined mr-2"
                        @click="showZWaveConfigDialog(curatedZWaveConfig)">
                  info
                </v-icon>
                {{ $t(curatedZWaveConfig.label) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn large depressed
                     color="primary"
                     class="font-weight-bold"
                     @click="setCuratedZWaveConfig(curatedZWaveConfig.parameter)">
                {{ $t('zwave-config.form.btn.play') }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-alert type="warning"
                 text
                 class="font-size-03 mb-5"
                 border="left">
          {{ $t('zwave-config.warning') }}
        </v-alert>

        <v-form ref="form">
          <v-row>
            <v-col>
              <v-text-field
                  type="number"
                  @blur="validateParam"
                  ref="zwaveParam"
                  :disabled="loading"
                  outlined
                  :label="$t('zwave-config.form.label.param')"
                  v-model="param"/>
              <v-text-field
                  type="number"
                  @blur="validateForm"
                  ref="zwaveValue"
                  :disabled="loading"
                  outlined
                  :label="$t('zwave-config.form.label.value')"
                  v-model="value"/>
            </v-col>
            <v-col>
              <v-radio-group class="pl-6 mt-0"
                             v-model="length"
                             :label="$t('zwave-config.form.label.length')"
                             :disabled="loading">
                <v-radio v-for="n in [1,2,4]"
                         :key="n"
                         :label="n.toString()"
                         :value="n"/>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-btn :disabled="validSet" depressed large class="mr-4" :color="color" @click="set">{{ $t('zwave-config.form.btn.save') }}</v-btn>
          <v-btn :disabled="validGet" depressed large outlined :color="color" @click="get">{{ $t('zwave-config.form.btn.read') }}</v-btn>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "ZwaveConfiguration",
  props: {
    device: Object,

    color: {
      default: "primary",
      type: String
    },
  },
  data: function () {
    return {
      param: null,
      length: 1,
      value: null,
      loading: false,
      valid: {
        param: false,
        value: false
      }
    }
  },
  methods: {
    get() {
      this.loading = true
      this.$rhRequest.sendGet({
        endpoint: `device/${this.device?.encryptedId}/configuration`,
        params: {
          param: this.param.toString()
        }
      }, (response) => {
        this.loading = false
        this.value = response?.data?.data?.value
      }, (err) => {
        console.error(err)
        this.$root.bisatoast.error({message: this.$t('zwave-config.toast.read.failed')})
        this.loading = false
      })
    },

    set(isCurated, params) {
      this.loading = true
      this.$rhRequest.sendPost({
        endpoint: `device/${this.device?.encryptedId}/configuration`,
        data: {
          param: isCurated === true ? params.number.toString() : this.param.toString(),
          length: isCurated === true ? params.bitLength.toString() : this?.length.toString(),
          value: isCurated === true ? params.value.toString() : this?.value.toString()
        }
      }, () => {
        this.loading = false
        this.param = null
        this.length = 1
        this.value = null
        this.$root.bisatoast.success({message: this.$t('zwave-config.toast.success')})
      }, (err) => {
        console.error(err)
        this.$root.bisatoast.error({message: this.$t('zwave-config.toast.write.failed')})
        this.loading = false
      })
    },

    setCuratedZWaveConfig(params) {
      if (params.length !== 0) {
        params.forEach((param) => {
          if (Object.hasOwn(param, 'bitLength') && Object.hasOwn(param, 'number') && Object.hasOwn(param, 'value')) {
            this.set(true, param)
          }
        })
      }
    },

    showZWaveConfigDialog(curatedZWaveConfig) {
      this.$root.bisadialog.toggle('infoText', true, {
        title: this.$t(curatedZWaveConfig.label),
        icon: 'info',
        text: this.$t(curatedZWaveConfig.description)
      })
    },

    validateForm() {
      this.valid.value = this.value?.length > 0 && !isNaN(this.value)
      this.validateParam()
    },

    validateParam() {
      this.valid.param = this.param?.length > 0 && !isNaN(this.param)
    }
  },
  computed: {
    validSet() {
      if (this.loading) return true;
      return !this.valid.param || !this.valid.value;
    },
    validGet() {
      if (this.loading) return true;
      return !this.valid.param;
    }
  }
}
</script>
