<template>
  <v-stepper-content step="11"
                     class="pb-2 pa-1">
    <div v-html="$t('add-rule-step-device-selection.info-text')"/>
    <device-selection :items="devices"
                      :loading="loading"
                      v-model="itemSelection"
                      @change="updateModel"/>
  </v-stepper-content>
</template>

<script>
import DeviceSelection from "@/templates/dialogs/automations/DeviceSelection.vue";

export default {
  name: 'AddRuleStepDeviceSelection',
  components: {
    DeviceSelection
  },

  props: {
    /**
     * v-model
     */
    value: {
      type: Object,
      default: null
    },
    /**
     * list of devices which should be excluded in the device selection
     */
    excludedItems: {
      type: Array
    },
  },

  data: function () {
    return {
      itemSelection: null,
      loading: false,
      items: [],
      devices: []
    }
  },

  computed: {
    /**
     * returns true if all inputs in this step are valid and the user is allowed to proceed to the next step
     * @returns {boolean}
     */
    valid() {
      return this.itemSelection !== null
    }
  },

  methods: {
    /**
     * call init, when the user is editing an action in a list of actions (as used in scenes) to pre-select the right
     * item in the device list
     *
     * @param item
     */
    init(item) {
      if (item == null) {
        this.getItems(null)
      } else {
        this.getItems(item)
        this.updateModel()
      }
    },

    /**
     * get all devices which are available for rule actions
     */
    getItems(selectedItem) {
      this.loading = true
      this.$rhRequest.sendGet({
        endpoint: "rule/device-actuator-items",
      }, (resp) => {
        let items = Object.values(resp?.data?.data)
        if (selectedItem === null) {
          // the user is adding a new action
          this.itemSelection = null
          // remove already assigned items from selection list
          this.excludedItems?.forEach(excludedItem => {
            if (items.filter(obj => {
              return obj.device?.id === excludedItem.device?.id
            })) {
              items.splice(items.findIndex(item => item.device?.id === excludedItem.device?.id), 1);
            }
          })
          this.items = items
        } else {
          // the user wants to edit an existing action
          // remove already assigned items from selection list EXCEPT for the selected item (edited action -> item may be used again)
          this.excludedItems?.forEach(excludedItem => {
            if (items.filter(obj => {
              return obj.device?.id === excludedItem.device?.id
            })) {
              items.splice(items.findIndex(item => item.device?.id === excludedItem.device?.id && item.device?.id !== selectedItem.device?.id), 1);
            }
          })

          // pre-select the previously selected device
          this.itemSelection = items.findIndex(value => value.device?.id === selectedItem.device?.id)
          this.items = items
          this.updateModel()
        }
        // set-up device list
        this.devices = []
        items?.forEach(item => {
          this.devices.push(item.device)
        })

        this.loading = false
      }, (err) => {
        console.error(err)
        this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
        this.loading = false
      })
    },

    /**
     * updates v-model
     */
    updateModel() {
      this.$emit('input', {
        itemId: this.itemSelection,
        device: this.items[this.itemSelection]?.device,
        actuator: this.items[this.itemSelection]?.actuator
      })
    }
  }
};
</script>
