<template>
  <v-card flat max-width="960" class="d-flex flex-column justify-space-between transparent account-deletion px-8 ma-md-auto">
    <v-card flat
            class="mt-8 transparent">
      <main-logo :fill-color="'var(--v-primary-base)'" class="mx-auto d-block logo"/>
      <div class="mt-10 form-container">
        <div class="subtitle-1" v-html="$t('account-deletion-page.general-information-title')"/>
        <div class="mb-4 font-size-02" v-html="$t('account-deletion-page.general-information')"/>
        <v-form v-model="valid" ref="form">
          <v-text-field outlined
                        v-model="email"
                        :disabled="sentSuccess"
                        :rules="emailRules"
                        required
                        :label="$t('login-page.e-mail.label')"/>
          <div class="subtitle-1" v-html="$t('account-deletion-page.reason-title')"/>
          <div class="mb-4 font-size-02" v-html="$t('account-deletion-page.reason-text')"/>
          <v-textarea outlined
                      v-model="reason"
                      :disabled="sentSuccess"
                      :rules="reasonRule"
                      counter="300"
                      height="120"
                      no-resize
                      :label="$t('form-dialog.textarea.label')"/>
          <div class="subtitle-1" v-html="$t('account-deletion-page.next-steps.title')"/>
          <div class="font-size-02" v-html="$t('account-deletion-page.next-steps')"/>

          <div class="mt-5" v-if="sentSuccess">
            <v-card flat color="var(--v-success-lighten3)" class="pa-4">
              <span class="font-size-02" v-html="$t('account-deletion-page.success')"/>
            </v-card>
          </div>
          <v-btn depressed x-large block
                 :loading="loading"
                 class="mt-8 font-weight-bold"
                 :disabled="loading || sentSuccess"
                 color="primary"
                 v-html="$t('app.send')"
                 @click="sendMail"/>
        </v-form>
      </div>

    </v-card>

    <div class="d-flex flex-wrap mx-auto transparent mb-4 mt-5">
      <v-btn color="primary"
             x-small
             text
             target="_blank"
             :href="$te('app.imprintLink') ? $t('app.imprintLink') : config.imprintLink">
        {{ $t('app.imprint') }}
      </v-btn>
      <v-btn color="primary"
             x-small
             text
             target="_blank"
             :href="$te('app.privacyLink') ? $t('app.privacyLink') :  config.privacyLink">
        {{ $t('app.privacy') }}
      </v-btn>
      <v-btn color="primary"
             x-small
             text
             target="_blank"
             :href="$te('app.tosLink') ? $t('app.tosLink') : config.tosLink">
        {{ $t('profile-page.tos') }}
      </v-btn>
    </div>
  </v-card>

</template>

<script>

import config from "@/config/config.app.json";
import mainLogo from "@/assets/custom/images/mainLogo.vue";

export default {
  name: "AccountDeletionPage",
  components: {mainLogo},
  data: () => ({
    config: config,
    email: '',
    reason: null,
    valid: false,
    sentSuccess: false,
    loading: false
  }),
  methods: {
    sendMail() {
      if (this.$refs.form.validate()) {
        this.loading = true
        let transferredReason = '-'
        if (this.reason !== null) {
          transferredReason = this.reason
        }
        let requestData = {
          email: this.email,
          reason: transferredReason
        }

        this.$rhRequest.sendPost({
          endpoint: "user/delete-account",
          data: requestData
        }, () => {
          this.sentSuccess = true
          this.loading = false
        }, () => {
          this.loading = false
        })
      }
    }
  },
  computed: {
    customWelcomeImageZoomPosition() {
      return {
        '--v-zoom-position': config.customWelcomeImageZoomPosition ? config.customWelcomeImageZoomPosition : 'center'
      }
    },
    emailRules() {
      return [
        v => !!v || this.$t('app.rules.required'),
        v => /.+@.+\..+/.test(v) || this.$t('app.rules.email-invalid')
      ]
    },
    reasonRule() {
      return [
        v => (!v || v.length <= 300) || this.$t('app.rules.too-long', {maxLength: 300})
      ]
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/entry/account-deletion.scss';
</style>
