import Vue from 'vue'
import CustomLogger from '@/plugins/bluetooth/deviceManager/Logger';
import CryptoJS from 'crypto-js';
import BltConnection from "@/plugins/bluetooth/deviceManager/Connection";
import FlowAuth from "@/plugins/bluetooth/deviceManager/flow/Auth";
import FlowOpen from "@/plugins/bluetooth/deviceManager/flow/Open";
import Discoverer from "@/plugins/bluetooth/deviceManager/Discoverer";

const MODULE_NAME_BLUETOOTH_DEVICE_MANAGER = 'blt device manager';
const WILKA_SDCS_SERVICE_UUID = '5DCBED00-9D94-11E5-8994-FEFF819CDC9F';
const WILKA_CHARACTERISTIC_UUID = '5DCBED20-9D94-11E5-8994-FEFF819CDC9F';

const AUTH_NOT_AUTHORIZED = 1;
const AUTH_IN_PROGRESS = 2;
const AUTH_AUTHORIZED = 3;

const DEVICE_CONNECTION_INTERVAL_ANDROID = 2000;
const DEVICE_CONNECTION_INTERVAL_IOS = 6000;


export default class {
  constructor() {
    this.devices = {};                  // holds current devices and their auth state
    this.callbacks = {};                // event callbacks
    this.connections = {};
    this.deviceReady = false;           // cordova event flag
    this.pause = false;                 // true = app in background
    this.bluetoothEnabled = false;
    this.active = false;                // global flag for activating this feature
    this.intervals = [];
    this.updateCooldown = false;        // Cooldown for update device list

    this.logger = new CustomLogger(MODULE_NAME_BLUETOOTH_DEVICE_MANAGER);

    this.logger.debug('initializing');
    this.discoverer = new Discoverer(WILKA_SDCS_SERVICE_UUID);

    this.bindEvents();

    this.logger.debug('initialized');
  }

  listenStateNotifications() {
    this.logger.debug('start listening for state notifications');
    // eslint-disable-next-line
    ble.startStateNotifications(
      this.onStateNotification.bind(this),
      (...args) => {
        this.logger.error('listen for state notifications failed', args);
      }
    );
  }

  onStateNotification(state) {
    this.logger.debug('onStateNotification', state);
    switch (state) {
      case 'on':
        this.onBluetoothIsEnabled();
        break;
      case 'off':
        this.onBluetoothIsDisabled();
        break;

    }
  }

  isBluetoothIsEnabled() {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      ble.isEnabled(
        () => {
          this.onBluetoothIsEnabled();
          resolve();
        },
        () => {
          this.onBluetoothIsDisabled();
          reject();
        }
      );
    });
  }

  update() {
    this.getKnownDevices();
    this.discover();
  }
  getKnownDevices() {
    if (!Vue.prototype.$rhAuth.loggedIn()) return;

    Vue.prototype.$rhRequest.sendGet(
      // get all types of wilka devices, "type" will get substituted in api
      { endpoint: "wilka/?fields[type]=name,batteryLevel,bluetoothId,description" },
      (...args) => {
        this.onKnownDevices(...args);
      },
      (...args) => {
        this.logger.error("getKnownDevices Error", ...args);
      }
    );
  }

  onKnownDevices(payload) {
    this.logger.debug('onKnownDevices', payload);
    this.notifyEventListener('known.devices', payload.data.data, payload.data.included);
    if (!this.isReady()) return;
    let batteryLevels = {};

    if (payload.data.included !== undefined) {
      payload.data.included.forEach((include) => {
        batteryLevels[include.id] = include.attributes.value;
      });
    }

    payload.data.data.forEach((device) => {
      let deviceId = device.attributes.bluetoothId;
      let deviceUuid = this.getDeviceUUIDFromLocalStorage(deviceId);

      if (window?.cordova?.platformId.toLowerCase() !== 'ios') {
        deviceUuid = deviceId;
      }

      if (!Object.keys(this.devices).includes(deviceId)) {
        let batteryLevelID = null;
        if (Object.keys(device).includes('relationships')) {
          batteryLevelID = device.relationships.batteryLevel.data.id;
          this.storeBatteryLevelToLocalStorage(deviceId, batteryLevels[batteryLevelID]);
        }

        this.devices[deviceId] = {
          mongoid: device.id,
          batteryLevelID: batteryLevelID,
          uuid: deviceUuid,
          authenticated: AUTH_NOT_AUTHORIZED
        };
      }

      if (deviceUuid !== null) {
        this.addConnection(deviceId, deviceUuid);
        this.getConnection(deviceId).connect();
      }
    });
    this.logger.debug('registered known devices', this.devices);
  }

  getDeviceUUIDFromLocalStorage(mac) {
    let deviceUUIDsJson = localStorage.getItem('bltDeviceUUIDs');
    let deviceUUIDs = JSON.parse(deviceUUIDsJson) ?? {};
    if (Object.keys(deviceUUIDs).includes(mac)) {
      return deviceUUIDs[mac];
    }

    return null;
  }

  storeBatteryLevelToLocalStorage(deviceID, batteryLevel) {
    this.logger.debug('storeBatteryLevelToLocalStorage', [deviceID, batteryLevel]);
    localStorage.setItem(this.getLocalStorageBatteryStateKey(deviceID), batteryLevel);
  }

  setDeviceUUIDtoLocalStorage(mac, uuid) {
    let deviceUUIDsJson = localStorage.getItem('bltDeviceUUIDs');
    let deviceUUIDs = JSON.parse(deviceUUIDsJson) ?? {};
    deviceUUIDs[mac] = uuid;
    localStorage.setItem('bltDeviceUUIDs', JSON.stringify(deviceUUIDs));
  }

  bindEvents() {
    this.discoverer.addEventListener('discoveredDevice', this.onDiscoveredDevice.bind(this));

    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
    document.addEventListener("resume", this.onResume.bind(this), false);
    document.addEventListener("pause", this.onPause.bind(this), false);
  }

  initIntervals() {
    let timeout = window?.cordova?.platformId.toLowerCase() === 'ios' ? DEVICE_CONNECTION_INTERVAL_IOS : DEVICE_CONNECTION_INTERVAL_ANDROID
    
    this.intervals.push(
      setInterval(() => { this.checkDeviceConnections(); }, timeout)
    );
  }

  stopIntervals() {
    this.intervals.forEach(interval => clearInterval(interval));
  }

  addEventListener(eventname, callback) {
    if (this.callbacks[eventname] === undefined) {
      this.callbacks[eventname] = [];
    }

    this.callbacks[eventname].push(callback);
  }

  removeEventListeners(eventname) {
    delete this.callbacks[eventname]
  }

  notifyEventListener(eventname, ...args) {
    this.logger.debug('notifyEventListener', { eventname: eventname, args: args });

    if (this.callbacks[eventname] === undefined) {
      return;
    }

    this.callbacks[eventname].forEach(callback => {
      callback(...args);
    });
  }

  setActive() {
    this.active = true;
    this.discoverer.setActive();
  }

  setInactive() {
    this.active = false;
    this.discoverer.setInactive();
  }

  getDeviceUUID(mac) {
    if (this.devices[mac] === undefined) {
      return null;
    }

    if (this.devices[mac].uuid === null) {
      this.devices[mac].uuid = this.getDeviceUUIDFromLocalStorage(mac);
    }

    if (window?.cordova?.platformId.toLowerCase() !== 'ios') {
      if (this.devices[mac].uuid === null) {
        this.devices[mac].uuid = this.getDeviceUUIDFromLocalStorage(mac);
      }
    }

    return this.devices[mac].uuid;
  }

  updateBatteryState(deviceID, batteryState) {
    this.logger.debug('onBatteryState', deviceID);

    let lsbs = localStorage.getItem(this.getLocalStorageBatteryStateKey(deviceID));
    this.logger.debug('onBatteryState lsbs', lsbs);

    if (lsbs === null || lsbs !== batteryState) {
      this.patchBatteryState(this.devices[deviceID].batteryLevelID, batteryState);
    }
  }

  discover() {
    this.setActive();
    this.discoverer.discover();
  }

  /**
   * Disconnects all connections
   */
  disconnect(force = false) {
    Object.entries(this.connections).forEach(
      (entry) => {
        this.resetConnection(entry[0], force);
      }
    );
  }

  patchBatteryState(mongoID, batteryState) {
    this.logger.debug('patchBatteryState', [mongoID, batteryState]);

    this.patchBatteryRequest(
      mongoID,
      {
        "data": {
          "id": mongoID,
          "type": "batteryLevels",
          "attributes": {
            "value": batteryState
          }
        }
      },
      this.logger.log,
      (...args) => {
        this.logger.error("updateBatteryState Error", ...args);
      }
    );
  }

  getLocalStorageBatteryStateKey(mac) {
    return CryptoJS.MD5('wilka_battery_state_' + mac).toString();
  }

  isReady() {
    if (!this.deviceReady) return false;
    if (!this.active) return false;
    if (!this.bluetoothEnabled) return false;
    if (this.pause) return false;
    if (!Vue.prototype.$rhAuth.loggedIn()) return false;
    return true;
  }

  requestAuth(mac) {
    Vue.prototype.$rhRequest.sendGet(
      { endpoint: 'wilka?filter[siteIds]=' + localStorage.activeSite + '&filter[bluetoothId]=' + mac + '&fields[lockCylinders]=communicationKey' },
      (response) => {
        let communicationKeyMD5 = response.data.data[0].attributes.communicationKey;
        this.auth(mac, communicationKeyMD5);
      },
      this.logger.debug.bind(this));
  }

  // Bluetooth related
  auth(mac, communicationKey) {
    if (!this.isReady()) return;

    if (this.devices[mac] === undefined) {
      return;
    }

    if (this.devices[mac].authenticated === AUTH_AUTHORIZED) {
      return;
    }

    if (this.devices[mac].authenticated === AUTH_IN_PROGRESS) {
      this.logger.debug('auth in progress', mac);
      return;
    }

    let connection = this.getConnection(mac);
    if (connection == null) return;

    connection.setEncryptionKey(communicationKey);
    connection.isConnected().then(
      () => {
        this.logger.debug('auth challenge starting', mac);
        this.devices[mac].authenticated = AUTH_IN_PROGRESS;
        let flowAuth = new FlowAuth(connection);

        flowAuth.onTimeout(() => {
          this.devices[mac].authenticated = AUTH_NOT_AUTHORIZED;
          this.logger.log('authentifaction failed', mac);
          this.resetConnection(mac);
          this.getConnection(mac).connect();
          this.notifyEventListener('wilka.disconnected', mac);
        });

        flowAuth.onError(() => {
          this.devices[mac].authenticated = AUTH_NOT_AUTHORIZED;
          this.logger.log('authentifaction failed', mac);
          this.notifyEventListener('wilka.disconnected', mac);
        });

        flowAuth.onSuccess(() => {
          this.devices[mac].authenticated = AUTH_AUTHORIZED;
          this.logger.log('authentifaction success', mac);
          this.notifyEventListener('wilka.connected', mac);
        });

        flowAuth.start();
      }
    );
  }

  openLock(mac) {
    if (!this.isReady()) return;

    let uuid = this.getDeviceUUID(mac);
    if (uuid === null) return;
    this.discoverer.setInactive();

    let connection = this.getConnection(mac);
    if (connection == null) return;
    let flowOpen = new FlowOpen(connection);
    flowOpen.onFinish(
      () => {
        this.discoverer.setActive();
      }
    );

    flowOpen.onSuccess(
      () => {
        this.notifyEventListener('wilka.open.success');
      }
    );

    flowOpen.onError(
      () => {
        this.notifyEventListener('wilka.open.error');
      }
    );

    flowOpen.start();
  }

  /**
   * Check for device is in Range
   */
  checkDeviceConnections() {
    if (!this.isReady()) return;
    for (var mac in this.devices) {
      this.checkConnection(mac);
    }
  }

  getConnection(mac) {
    if (!Object.keys(this.connections).includes(mac)) {
      return null;
    }
    return this.connections[mac];
  }

  resetConnection(mac, force = false) {
    this.logger.debug('reset connection', mac);
    let connection = this.getConnection(mac);
    if (connection == null) return;
    let deviceUUID = connection.getDeviceUUID();

    if (force) {
      this.devices[mac].authenticated = AUTH_NOT_AUTHORIZED;
      connection.disconnect();
    } else {
      this.devices[mac].authenticated = AUTH_NOT_AUTHORIZED;
      connection.isConnected().then(
        () => { connection.disconnect() }
      );
    }

    if (Object.keys(this.connections).includes(mac)) {
      this.connections[mac] = null;
      delete this.connections[mac];
      this.addConnection(mac, deviceUUID);
    }

    this.notifyEventListener('wilka.disconnected', mac);
  }

  checkConnection(mac) {
    let connection = this.getConnection(mac);
    if (connection == null) return;
    if (this.devices[mac].authenticated === AUTH_NOT_AUTHORIZED) {
      connection.connect();
    }

    connection.isConnected().then(
      () => {
        if (this.devices[mac].authenticated === AUTH_NOT_AUTHORIZED) {
          this.requestAuth(mac);
        } else if (this.devices[mac].authenticated === AUTH_AUTHORIZED) {
          this.notifyEventListener('wilka.connected', mac);
        }
      })
      .catch(
        (isConnecting) => {
          if (!isConnecting) this.resetConnection(mac)
        }
      );
  }

  addConnection(deviceId, deviceUuid) {
    if (Object.keys(this.connections).includes(deviceId)) return;
    this.logger.debug('addConnection: connection added for', [deviceId, deviceUuid]);

    let connection = new BltConnection(deviceUuid, WILKA_SDCS_SERVICE_UUID, WILKA_CHARACTERISTIC_UUID);
    connection.onNotificationError(this.onNotificationError.bind(this));

    connection.onConnect(
      (state) => {
        if (state) {
          this.discoverer.setActive();
          this.logger.debug("connected -> requesting auth");
          this.requestAuth(deviceId)
        }
      }
    );
  
    this.connections[deviceId] = connection;
    return this.connections[deviceId];
  }

  updateDeviceUuid(deviceId, deviceUuid) {
    if (window?.cordova?.platformId.toLowerCase() !== 'ios') return;
    this.setDeviceUUIDtoLocalStorage(deviceId, deviceUuid);
    this.devices[deviceId].uuid = this.getDeviceUUIDFromLocalStorage(deviceId);
  }

  patchBatteryRequest(mongoID, data, onSuccess, onError) {
    let self = this;
    Vue.prototype.$rhRequest.sendPatch(
      {
        endpoint: "wilka/battery-level/" + mongoID,
        data: data
      },
      onSuccess.bind(self),
      onError.bind(self)
    )
  }

  // Event Listener
  onDiscoveredDevice(payload) {
    this.logger.debug('onDiscoveredDevice', [payload, this.devices]);
    if (!Object.keys(this.devices).includes(payload.mac)) {
      this.logger.debug('Unknown device discovered (abort)', payload);
      return;
    }

    this.updateBatteryState(payload.mac, payload.batteryState);
    this.updateDeviceUuid(payload.mac, payload.uuid);

    let connection = this.addConnection(payload.mac, payload.uuid);
    if (connection != null) connection.connect();
  }

  onNotificationError(response) {
    let mac = response.getMac();
    if (response.getType() == 0xFD && response.getState() == 0x03) {
      // 0x03 checksum is not valid, thats an indicator for invalid encryption key
      this.resetConnection(mac);
      this.getConnection(mac).connect();
    }
    this.discoverer.setActive();
  }

  onError(...args) {
    this.logger.error('BLE ERROR', ...args);
  }

  onPause() {
    this.logger.debug('onPause', 'app in pause');
    this.discoverer.onPause();
    this.disconnect(true);
    this.stopIntervals();
    this.pause = true;
  }

  onResume() {
    this.logger.debug('onResume', 'app has focus again');
    this.discoverer.onResume();
    this.initIntervals();
    this.getKnownDevices();
    this.pause = false;
  }

  onBluetoothIsEnabled() {
    this.logger.debug('onBluetoothIsEnabled', 'bluetooth enabled');
    this.bluetoothEnabled = true;
    this.notifyEventListener('bluetooth.enabled');
    setTimeout(() => {
      this.setActive()
      this.discoverer.onBluetoothEnabled();
      this.checkDeviceConnections();
    }, 3000);
  }

  onBluetoothIsDisabled() {
    this.logger.debug('onBluetoothIsDisabled', 'bluetooth disabled');
    this.bluetoothEnabled = false;
    this.disconnect(true);
    this.discoverer.onBluetoothDisabled();
    this.setInactive();
    this.notifyEventListener('bluetooth.disabled');
  }

  onDeviceReady() {
    this.deviceReady = true;
    this.initIntervals();
    this.listenStateNotifications();
    this.isBluetoothIsEnabled();
    this.notifyEventListener('deviceReady');
  }

  onLoggedInChanged(loggedIn) {
    if (!loggedIn) {
      this.logger.debug('User no longer logged in.');
      this.setInactive();
      this.disconnect(true);
    }
  }
}
