<template>
  <v-expansion-panels :ref="'enode-advanced-settings-' + vehicleId" class="enode-advanced-setting" v-model="panel" accordion>
    <v-expansion-panel v-model="panel" class="tileBackground">
      <v-expansion-panel-header class="font-weight-bold">{{ $t('enode-connection-tile.advanced-settings.title') }}</v-expansion-panel-header>
      <v-expansion-panel-content class="pa-0">
        <v-skeleton-loader v-if="loadingRules"
                           type="article"
                           height="100"/>
        <v-simple-table v-else>
          <template v-slot:default>
            <tbody class="tileBackground">
            <tr :class="{'disabled-table-row': !hasGateway}">
              <td @click="hasGateway ? openTimeSettings() : showTipsDialog(false)">
                <v-list-item class="list-item enode-rules-list-item straight">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('enode-connection-tile.advanced-settings.time-based.title') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('enode-connection-tile.advanced-settings.time-based.subtitle', {time: displayTriggerTime}) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </td>
              <td>
                <v-switch class="mt-0"
                          v-if="hasGateway"
                          v-model="timeBasedChargingRule.active"
                          :disabled="loadingTimeBasedRule"
                          @change="toggleTimeBasedCharging"
                          inset hide-details/>
                <v-icon class="material-icons-outlined"
                        @click="showTipsDialog(false)"
                        v-else
                        color="primary"
                        left>info
                </v-icon>
              </td>
              <td @click="hasGateway ? openTimeSettings() : showTipsDialog(false)">
                <v-icon color="primary">chevron_right</v-icon>
              </td>
            </tr>

            <tr :class="{'disabled-table-row': !hasMeter}">
              <td @click="hasMeter ? openGreenChargingSettings() : showTipsDialog(true)">
                <v-list-item class="list-item enode-rules-list-item straight">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{ $t('enode-connection-tile.advanced-settings.green-charging.title') }}</v-list-item-title>
                    <v-list-item-subtitle v-if="greenChargingRule.hasRule">{{ $t('enode-connection-tile.advanced-settings.green-charging.subtitle', {value: greenChargingRule.greenChargingVal}) }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else>{{ $t('enode-connection-tile.advanced-settings.green-charging.subtitle-empty-state') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </td>
              <td>
                <v-switch class="mt-0"
                          v-if="hasMeter"
                          v-model="greenChargingRule.active"
                          :disabled="loadingGreenChargingRule"
                          @change="greenChargingRule.hasRule ? saveGreenChargingRule() : openGreenChargingSettings()"
                          inset hide-details/>
                <v-icon class="material-icons-outlined"
                        @click="showTipsDialog(true)"
                        v-else
                        color="primary"
                        left>info
                </v-icon>
              </td>
              <td @click="hasMeter ? openGreenChargingSettings() : showTipsDialog(true)">
                <v-icon color="primary">chevron_right</v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import moment from "moment";
import requestHelper from "@/scripts/requestHelper";
import config from "@/config/config.app.json";

export default {
  name: "EnodeAdvancedSettings",
  props: ['vehicleId'],
  data() {
    return {
      panel: null,
      changeTime: false,
      changeFeed: false,
      hasGateway: false,
      hasMeter: false,
      meterList: [],
      loadingRules: false,
      loadingTimeBasedRule: false,
      loadingGreenChargingRule: false,
      greenChargingRule: {
        hasRule: false,
        active: false,
        meterDevice: null,
        greenChargingVal: 0
      },
      timeBasedChargingRule: {
        hasRule: false,
        active: false,
        triggerTime: "22:00:00"
      }
    }
  },
  methods: {
    getAutomationAttributes() {
      this.$rhRequest.sendGet({
        endpoint: 'enode/get-automation-attributes'
      }, (response) => {
        this.hasGateway = response?.data?.data?.hasGateway
        this.hasMeter = response?.data?.data?.hasMeter
      }, (error) => {
        console.error(error)
      })
    },
    showTipsDialog(greenCharging = false) {
      let text = greenCharging ? this.$t('enode-connection-tile.info-dialog.info.text-2') : this.$t('enode-connection-tile.info-dialog.info.text')

      this.$root.bisadialog.toggle('infoText', true, {
        title: this.$t('enode-connection-tile.info-dialog.title'),
        icon: 'info',
        text: text
      })

    },
    openTimeSettings() {
      this.$root.bisadialog.toggle('enodeTimeSetting', true, {triggerTime: this.displayTriggerTime, vehicleId: this.vehicleId})
      this.$root.bisadialog.callDialogInit('enodeTimeSetting')
    },
    openGreenChargingSettings() {
      this.$root.bisadialog.toggle('enodeGreenChargingSetting', true, {meterList: this.meterList, vehicleId: this.vehicleId})
      this.$root.bisadialog.callDialogInit('enodeGreenChargingSetting')
    },
    /**
     * continuously updates rules
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getRules(false)
      }, config.updateInterval)
    },
    getRules(loadingScreen = true) {
      this.loadingRules = loadingScreen

      this.$rhRequest.sendGet({
        endpoint: 'enode/get-rules',
        params: {
          "filter[action.vehicleId]": this.vehicleId
        }
      }, (resp) => {
        this.sortRules(resp?.data?.data)
        this.loadingRules = false
        this.panel = 0
      }, (err) => {
        this.loadingRules = false
        this.panel = 0
        console.error(err)
      })
    },
    sortRules(rules) {
      if (rules) {
        rules.forEach((rule) => {
          if (rule?.attributes?.condition?.type === 'timers') {
            this.timeBasedChargingRule.hasRule = true
            this.timeBasedChargingRule.id = rule?.id
            this.timeBasedChargingRule.triggerTime = moment(rule?.attributes?.condition?.triggerTime, "HH:mm:ss").format("HH:mm:ss")
            this.timeBasedChargingRule.active = !rule?.attributes?.deactivated
          } else if (rule?.attributes?.condition?.type === 'properties') {
            this.greenChargingRule.hasRule = true
            this.greenChargingRule.id = rule?.id
            this.greenChargingRule.meterDevice = rule?.attributes?.condition?.deviceId
            this.greenChargingRule.greenChargingVal = Number(rule?.attributes?.condition?.value)
            this.greenChargingRule.active = !rule?.attributes?.deactivated
          }
        })
      }
    },
    saveTimeBasedRule(isTimeChange, triggerTime) {
      this.changeTime = isTimeChange

      if (isTimeChange) {
        this.timeBasedChargingRule.triggerTime = triggerTime
      }
      if (this.timeBasedChargingRule.hasRule) {
        this.updateRule('timeBased')
      } else {
        this.addRule('timeBased')
      }
    },
    saveGreenChargingRule() {
      if (this.greenChargingRule.hasRule) {
        this.updateRule('greenCharging')
      } else {
        this.addRule('greenCharging')
      }
    },
    toggleTimeBasedCharging() {
      this.saveTimeBasedRule(false)
    },
    addRule(type) {
      let rule = this.manageRule(true, type)
      this.$rhRequest.sendPost({
        endpoint: 'rule/add',
        data: rule.data
      }, () => {
        this.manageRule(false, type)
        this.getRules()
      }, (err) => {
        this.manageRule(false, type)
        console.error(err)
      })
    },
    updateRule(type) {
      let rule = this.manageRule(true, type)

      this.$rhRequest.sendPost({
        endpoint: 'rule/update',
        params: {
          "id": rule.id
        },
        data: rule.data
      }, () => {
        this.manageRule(false, type)
        this.getRules()
      }, (err) => {
        this.manageRule(false, type)
        console.error(err)
      })
    },
    manageRule(startLoading, type) {
      if (startLoading) {
        let data, id
        if (type === 'timeBased') {
          data = this.timeBasedChargingData
          id = this.timeBasedChargingRule?.id
          this.loadingTimeBasedRule = true
        } else if (type === 'greenCharging') {
          data = this.greenChargingData
          id = this.greenChargingRule?.id
          this.loadingGreenChargingRule = true
        }
        return {
          id: id,
          data: data
        }
      } else {
        if (type === 'timeBased') {
          this.loadingTimeBasedRule = false
        } else if (type === 'greenCharging') {
          this.loadingGreenChargingRule = false
        }
      }
    }
  },
  mounted() {
    this.getAutomationAttributes()
    this.getRules()

    requestHelper.startDelayedRequest(
        () => this.getRules(),
        () => this.updateData()
    )

    // Set listener for component specific triggerTimeChange
    this.$root.$on('triggerTimeChange-' + this.vehicleId, (isTimeChange, triggerTime) => {
      this.saveTimeBasedRule(isTimeChange, triggerTime)
    })

    // Set listener for component specific triggerGreenCharging
    this.$root.$on('triggerGreenCharging-' + this.vehicleId, (meterDevice) => {
      this.greenChargingRule.greenChargingVal = meterDevice.value
      this.greenChargingRule.meterDevice = meterDevice.deviceId
      this.saveGreenChargingRule()
    })
  },
  beforeDestroy() {
    this.$root.$off('triggerTimeChange-' + this.vehicleId)
    this.$root.$off('triggerGreenCharging-' + this.vehicleId)
  },
  computed: {
    displayTriggerTime() {
      return moment(this.timeBasedChargingRule.triggerTime, "HH:mm:ss").format("HH:mm")
    },
    timeBasedChargingData() {
      return {
        "name": "Planned Charging: " + this.vehicleId,
        "iconKey": "auto_awesome",
        "deactivated": this.timeBasedChargingDeactivated,
        "condition": {
          "type": "timers",
          "weekdays": [true, true, true, true, true, true, true],
          "startTime": moment().format("YYYY-MM-DD") + " 00:00:00",
          "triggerTime": this.timeBasedChargingRule.triggerTime,
          "timerType": "DAILY",
          "timezone": moment.tz.guess()
        },
        "action": {
          "type": "chargeActions",
          "vehicleId": this.vehicleId,
          "action": "START"
        },
        "synced": "PENDING",
        "hidden": true
      }
    },
    greenChargingData() {
      return {
        "name": "Green charging: " + this.vehicleId,
        "iconKey": "auto_awesome",
        "deactivated": this.greenChargingDeactivated,
        "condition": {
          "type": "properties",
          "deviceId": this.greenChargingRule.meterDevice,
          "property": "feed-current",
          "constraint": ">",
          "value": this.greenChargingRule.greenChargingVal
        },
        "action": {
          "type": "chargeActions",
          "vehicleId": this.vehicleId,
          "action": "START"
        },
        "synced": "PENDING",
        "hidden": true
      }
    },
    timeBasedChargingDeactivated() {
      if (this.changeTime) {
        return this.timeBasedChargingRule.active
      } else {
        return !this.timeBasedChargingRule.active
      }
    },
    greenChargingDeactivated() {
      if (this.changeFeed) {
        return this.greenChargingRule.active
      } else {
        return !this.greenChargingRule.active
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/e-mobility.scss';
</style>
