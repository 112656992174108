<template>
  <v-stepper-content step="10"
                     class="pb-2 pa-1">

    <div v-if="type === 'rule'" v-html="$t('add-rule-step-action-type.info-text')"/>
    <div v-else-if="type === 'scene'" v-html="$t('add-rule-step-action-type.info-text.scene')"/>

    <content-card :title="$t('add-rule-dialog.select-action.title').toString()"
                  icon="smart_button"
                  class="my-5">
      <template v-slot:content>
        <v-list class="pa-0">
          <v-radio-group v-model="actionSelection"
                         class="pa-0 ma-0"
                         @change="updateModel"
                         hide-details>
            <!-- device action -->
            <v-list-item class="list-item straight">
              <v-list-item-icon class="mr-2">
                <v-radio :value="automations.actionTypes.devices"
                         class="pt-2 mr-2"/>
                <v-icon color="primary">widgets</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold font-size-03"
                                   v-text="$t('add-rule-step-action-type.devices')"/>
              </v-list-item-content>
            </v-list-item>

            <!-- scene action -->
            <v-list-item class="list-item straight">
              <v-list-item-icon class="mr-2">
                <v-radio :value="automations.actionTypes.sceneRefs"
                         class="pt-2 mr-2"/>
                <v-icon color="primary">play_circle_outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold font-size-03"
                                   v-text="$t('add-rule-step-action-type.scenes')"/>
              </v-list-item-content>
            </v-list-item>

            <!-- notification action -->
            <v-list-item class="list-item straight">
              <v-list-item-icon class="mr-2">
                <v-radio :value="automations.actionTypes.notifications"
                         class="pt-2 mr-2"/>
                <v-icon color="primary">notifications</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold font-size-03"
                                   v-text="$t('add-rule-step-action-type.notifications')"/>
              </v-list-item-content>
            </v-list-item>
          </v-radio-group>
        </v-list>
      </template>
    </content-card>

  </v-stepper-content>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import automations from "@/scripts/automations";

export default {
  name: 'AddRuleStepActionType',
  components: {
    ContentCard
  },

  props: ['value', 'type'],

  data: function () {
    return {
      actionSelection: automations.actionTypes.devices
    }
  },

  computed: {
    automations() {
      return automations
    }
  },

  methods: {
    setActionType(actionType) {
      this.actionSelection = actionType
    },

    updateModel() {
      this.$emit('input', this.actionSelection)
    }
  }
};
</script>
