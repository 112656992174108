<!--
  Renders one sensor subdevice.
-->
<template v-slot="{ dense:dense }">
  <div>
    <!-- dense view -->
    <v-tab v-if="dense"
           class="mx-auto"
           :disabled="disabled"
           :ripple="false">
            <span v-if="isAlert"
                  class="text-none mt-2">
              {{ alertText }}
            </span>
      <span v-else-if="value"
            class="text-none mt-2">
              {{ value }}
            </span>
      <span v-else
            class="text-none mt-2">
              {{ localizedValue }} {{ sensor?.unit }}
            </span>
      <v-icon large
              :color="color">
        {{ sensorIcon }}
      </v-icon>
    </v-tab>

    <!-- large view -->
    <v-list-item v-else
                 :disabled="disabled"
                 class="list-item">
      <v-list-item-icon>
        <v-icon :color="color" :class="isNonFlipIcon(sensorIcon ?? 'question_mark') ? 'non-flip' : ''">
          {{ sensorIcon ?? 'question_mark' }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-if="isAlert"
                           class="font-size-03">
          {{ alertText }}
        </v-list-item-title>
        <v-list-item-title v-else-if="value"
                           class="always-ltr-text font-size-03">
          {{ value }}
        </v-list-item-title>
        <v-list-item-title v-else
                           class="always-ltr-text font-size-03">
          {{ localizedValue }} {{ sensor?.unit }}
        </v-list-item-title>
        <v-list-item-subtitle class="font-size-02" v-html="label !== '' ? label : $t('sensor-device.unsupported-sensor')"/>
      </v-list-item-content>
      <v-list-item-action v-if="sensor">
        <v-btn icon v-if="!hideHistory"
               @click.stop="showEventHistory = !showEventHistory">
          <v-icon class="non-flip" :color="showEventHistory ? 'primary' : ''">history</v-icon>
        </v-btn>
        <slot/>
      </v-list-item-action>
    </v-list-item>

    <!-- device event history display -->
    <device-event-history v-if="showEventHistory"
                          :device-id="deviceId"
                          :event="sensor"/>
  </div>
</template>

<script>
import DeviceEventHistory from "@/templates/components/devices/DeviceEventHistory.vue";
import deviceProperties from "@/config/deviceProperties.json";
import formats from "@/scripts/formats";
import {isNonFlipIcon} from "@/i18n";

export default {
  name: 'SensorDevice',
  methods: {isNonFlipIcon},
  components: {DeviceEventHistory},

  props: {
    sensor: Object,
    deviceId: Number,
    dense: Boolean,
    disabled: Boolean,
    value: [Number, String],
    unit: String,
    label: String,
    icon: String
  },

  data: function () {
    return {
      isAlert: deviceProperties[this.sensor?.name]?.isAlert,
      showEventHistory: false
    }
  },

  computed: {
    /**
     * icon color:
     * 'error' if the sensor is configured with isAlert=true and the sensor value is 'true'
     * 'primary' in all other cases
     *
     * @returns {string}
     */
    color() {
      if (this.isAlert && this.sensor?.value === true) {
        return 'error'
      }
      return 'primary'
    },

    hideHistory() {
      return deviceProperties[this.actuator?.name]?.view?.hideHistory === true
    },

    /**
     * sub-device icon
     * alert icons if the sensor is configured with isAlert=true or the passed icon string
     *
     * @returns {*|string}
     */
    sensorIcon() {
      if (this.isAlert && this.sensor?.value === true) {
        return 'notifications_active'
      } else if (this.isAlert && this.sensor?.value === false) {
        return 'notifications'
      }
      return this.icon
    },

    /**
     * returns the alert text for a sensor with isAlert=true
     *
     * @returns {*|null}
     */
    alertText() {
      if (this.sensor?.value === true) {
        return this.$t(deviceProperties[this.sensor?.name]?.activeLabel) ?? null
      }
      return this.$t(deviceProperties[this.sensor?.name]?.inactiveLabel) ?? null
    },

    /**
     * localize float value
     *
     * @returns {string}
     */
    localizedValue() {
      return formats.localize(this.sensor)
    }
  }
}
</script>
